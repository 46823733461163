import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import {IconNames} from '../ValmetIcon/IconNames';
import ValmetIcon from '../ValmetIcon';
import colors from '../../configs/colors';
const {blue2, grey11} = colors;
export interface TopAction {
  icon: IconNames;
  onClick: () => void;
}

interface Props {
  actions?: readonly TopAction[];
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    borderTop: '1px solid #D2D2D2',
    height: '48px',
    padding: '8px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttons: {
    height: '32px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '7px 12px 7px 1px',
    borderLeft: `1px solid ${grey11}`,
  },
  button: {
    fontSize: '18px',
    color: blue2,
    marginLeft: '34px',
    cursor: 'pointer',
  },
}));

const TopActions = (props: Props) => {
  const classes = useStyles(props);

  if (props.actions === undefined || props.actions.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        {props.actions.map(a => (
          <div key={a.icon} className={classes.button} onClick={a.onClick}>
            <ValmetIcon icon={a.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopActions;
