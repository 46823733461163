import {ClickAwayListener, makeStyles, Paper, Popper} from '@material-ui/core';
import React from 'react';
import {Option, SingleSelectOption} from '../Inputs/ValmetSelect';

/*
 * This component is a recreation of the menu used
 * within the Autocomplete component of MUI.
 * You can see the source code here:
 * https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/Autocomplete/Autocomplete.js
 */

export interface Props {
  anchorEl: Element | null;
  options: Option[];
  selectedOption: Option | null;
  onOptionSelected: (option: Option) => void;
  onClose: () => void;
}

const useStyles = makeStyles(
  theme => ({
    popper: {
      zIndex: theme.zIndex.modal,
    },
    paper: {
      ...theme.typography.body1,
      overflow: 'hidden',
      margin: '4px 0',
    },
    listbox: {
      listStyle: 'none',
      margin: 0,
      padding: '8px 0',
      maxHeight: '40vh',
      overflow: 'auto',
    },
    option: {
      minHeight: 48,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      paddingTop: 6,
      boxSizing: 'border-box',
      outline: '0',
      WebkitTapHighlightColor: 'transparent',
      paddingBottom: 6,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: {
        minHeight: 'auto',
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.action.selected,
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.selected,
      },
      '&[aria-disabled="true"]': {
        opacity: theme.palette.action.disabledOpacity,
        pointerEvents: 'none',
      },
    },
    // These are defined to avoid warnings in console.
    // We specify theme overrides for these classes for
    // Autocomplete and if they are not here it complains.
    hasPopupIcon: {},
    popupIndicator: {},
    clearIndicator: {},
  }),
  {name: 'MuiAutocomplete'},
);

export default function SingleSelectMenu({
  anchorEl,
  options,
  selectedOption,
  onClose,
  onOptionSelected,
}: Props) {
  const classes = useStyles();
  const listboxRef = React.useRef<HTMLUListElement>(null!);
  const highlightedIndexRef = React.useRef(-1);

  const onOptionClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      const index = Number(
        event.currentTarget.getAttribute('data-option-index'),
      );
      // eslint-disable-next-line security/detect-object-injection
      onOptionSelected(options[index]);
      onClose();
    },
    [onClose, onOptionSelected, options],
  );

  const onOptionMouseOver = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      if (!listboxRef.current) {
        return;
      }

      const prev = listboxRef.current.querySelector('[data-focus]');
      if (prev) {
        prev.removeAttribute('data-focus');
      }

      const option = event.currentTarget;
      const index = Number(option.getAttribute('data-option-index'));
      highlightedIndexRef.current = index;
      option.setAttribute('data-focus', 'true');
    },
    [],
  );

  if (anchorEl === null) {
    return null;
  }

  return (
    <ClickAwayListener
      onClickAway={onClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Popper
        open
        anchorEl={anchorEl}
        className={classes.popper}
        style={{width: '250px'}}
        role="presentation"
        placement="bottom-start"
      >
        <Paper className={classes.paper}>
          <ul className={classes.listbox} ref={listboxRef} role="listbox">
            {options.map((o, i) => (
              <li
                key={o.value}
                className={classes.option}
                tabIndex={-1}
                role="option"
                data-option-index={i}
                aria-disabled="false"
                aria-selected={selectedOption?.value === o.value}
                onClick={onOptionClick}
                onMouseOver={onOptionMouseOver}
              >
                <SingleSelectOption option={o} />
              </li>
            ))}
          </ul>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}
