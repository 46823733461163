import {ITableColumnSettings} from './types';
import React from 'react';
import {EXPANDER_CELL_ID, STATIC_CELL_WIDTH} from './ClientSide/index';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import colors from '../../configs/colors';
import {Box} from '@material-ui/core';
import {Row, UseExpandedRowProps} from 'react-table';
import {useStyles} from './styles';
import classNames from 'classnames';

export const rowExpanderColumn = (
  allRowCanExpand?: boolean,
): ITableColumnSettings => {
  return {
    id: EXPANDER_CELL_ID, // It needs an ID
    disableResizing: true,
    disableSortBy: true,
    sticky: 'left',
    minWidth: STATIC_CELL_WIDTH,
    width: STATIC_CELL_WIDTH,
    maxWidth: STATIC_CELL_WIDTH,
    Header: '',
    Cell: ({row}) => {
      const classes = useStyles();

      if (!allRowCanExpand && !row.canExpand && row.depth === 0) {
        return (
          <Box
            className={classNames({
              [classes.tdExpandCell]: row.depth !== 0,
            })}
            style={{cursor: 'default'}}
          ></Box>
        );
      }

      return (
        <Box
          className={classNames(classes.tdExpandCell, {
            [classes.trIsSub]: row.depth,
          })}
          {...row.getToggleRowExpandedProps({})}
        >
          <Box
            style={{border: `1px solid ${colors.grey2}`}}
            display="inline-flex"
            fontSize={row.depth ? '13px' : '17px'}
          >
            {row.isExpanded ? (
              <RemoveIcon fontSize="inherit" />
            ) : (
              <AddIcon fontSize="inherit" />
            )}
          </Box>
        </Box>
      );
    },
  };
};

export const ExpandedRowContent = ({
  children,
  row,
}: {
  children: JSX.Element;
  row: Row<any> & UseExpandedRowProps<any>;
}): JSX.Element | null => {
  return row.isExpanded ? (
    <Box
      style={{
        borderLeft: `4px solid ${colors.blue5}`,
        borderBottom: `${row.depth ? '2px' : '3px'} solid ${colors.grey13}`,
        borderRight: `1px solid ${colors.grey12}`,
      }}
    >
      {children}
    </Box>
  ) : null;
};
