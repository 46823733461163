import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import colors from '../../configs/colors';
const {grey11, grey14} = colors;
interface Props {}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: grey14,
    borderLeft: `1px solid ${grey11}`,
    borderRight: 'none',
    '&:last-of-type': {
      borderRight: `1px solid ${grey11}`,
    },
  },
}));

const HeaderCellBase: React.FC<Props> = props => {
  const classes = useStyles(props);
  return <div className={classes.root}>{props.children}</div>;
};

export default HeaderCellBase;
