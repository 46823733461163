import React from 'react';
import {makeStyles, Theme, Typography} from '@material-ui/core';
import colors from '../../configs/colors';
import CellBase from './CellBase';
const {grey3} = colors;
interface Props {
  isOddRow: boolean;
  text: string;
  isHighlighted?: boolean;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  text: {
    color: grey3,
    fontSize: '13px',
    lineHeight: '15px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: props => (props.isHighlighted ? 'bold' : 'normal'),
    textDecorationLine: props => (props.isHighlighted ? 'underline' : 'none'),
  },
}));

const SingleLineTextCell = (props: Props) => {
  const {text, isHighlighted, ...rest} = props;
  const classes = useStyles(props);
  return (
    <CellBase {...rest}>
      <Typography className={classes.text}>{text}</Typography>
    </CellBase>
  );
};

export default SingleLineTextCell;
