import React, {useState, useLayoutEffect, useEffect, useRef} from 'react';
import SettingsHeaderCell from './SettingsHeaderCell';
import HeaderCell from './HeaderCell';
import Row from './Row';
import SingleLineTextCell from './SingleLineTextCell';
import MultiLineTextCell from './MultiLineTextCell';
import TimestampInfoCell, {TimestampInfo} from './TimestampInfoCell';
import ActionsCell from './ActionsCell';
import HeaderRow from './HeaderRow';
import {CircularProgress, makeStyles, Theme} from '@material-ui/core';
import {update} from 'ramda';
import {Sort, Column, TableSortDirection, RowAction} from './types';
import NoResultsRow from './NoResultsRow';
import {
  useValmetTableSettings,
  ValmetTableSettingsProvider,
} from './valmet-table-settings-context';

const DefaultColumnWidth = 160;

export type InitialSort = Sort | undefined;

export type Row = {id: string} & {
  [dataKey: string]: string | readonly string[] | Readonly<TimestampInfo>;
};

export interface Props<
  TRow extends Row,
  TInitialSort extends InitialSort = undefined,
> {
  /**
   * Definitions for the columns,
   * please do memoize this (or use a constant)
   * to reduce re-renders
   */
  columns: readonly Column[];
  /**
   * The rows to show
   */
  data: readonly TRow[];
  /**
   * Actions the user can do on each row,
   * shown in a menu in the right-most column
   */
  actions: readonly RowAction<TRow>[];
  /**
   * Initial sort column and direction
   */
  initialSort: TInitialSort;
  /**
   * How the row actions are shown.
   * Inline: icons on left side of menu button.
   * Popup: icons + text in a menu under menu button.
   * Default is inline.
   */
  actionsMenuMode?: 'inline' | 'popup';
  /**
   * Tooltip shown to user when they hover over the
   * action menu button if it is disabled.
   */
  actionsMenuNoItemsTooltip?: string;
  getActionsForRow?: (
    row: TRow,
    globalActions: readonly RowAction<TRow>[],
  ) => readonly RowAction<TRow>[];
  /**
   * Called when sort column/direction changes
   * Arguments are undefined if sorting was reset and no initial sort was provided
   */
  onSortChange: (sort: Sort | TInitialSort) => void;
  /**
   * Message shown to user when there are no search results to show.
   */
  noResultsMessageTranslationKey?: string;
  /**
   * If true, indicates to the user that data is loading
   */
  isLoading?: boolean;
  onRowClick?: (row: TRow, rowIndex: number) => void;
}

const useStyles = makeStyles<Theme, {rowHeights: number[]}>(() => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 34px',
    gridTemplateRows: 'auto',
  },
  content: {
    overflowX: 'auto',
    paddingBottom: '5px',
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: '34px',
    gridTemplateRows: props =>
      `44px ${props.rowHeights.map(h => `${h}px`).join(' ')}`,
  },
  loadingSpinner: {
    textAlign: 'center',
    paddingTop: '5px',
  },
}));
function ValmetTable<
  TRow extends Row,
  TInitialSort extends InitialSort = undefined,
>(props: Props<TRow, TInitialSort>) {
  return (
    <ValmetTableSettingsProvider>
      <Wrapper {...props} />
    </ValmetTableSettingsProvider>
  );
}
function Wrapper<
  TRow extends Row,
  TInitialSort extends InitialSort = undefined,
>(props: Props<TRow, TInitialSort>) {
  const {visibleColumns, setVisibleColumns} = useValmetTableSettings();
  return (
    <ValmetTableSource
      {...props}
      setVisibleColumns={setVisibleColumns}
      visibleColumns={visibleColumns}
    />
  );
}
export function ValmetTableSource<
  TRow extends Row,
  TInitialSort extends InitialSort = undefined,
>(
  props: Props<TRow, TInitialSort> & {
    // we can set visible/hidden columns on table mount from parent
    setVisibleColumns: (visibleColumns: boolean[]) => void;
    // we can read visible/hidden columns on table mount
    visibleColumns: boolean[];
  },
) {
  const {setVisibleColumns, visibleColumns} = props;

  const [sort, setSort] = useState<Sort | undefined>(props.initialSort);
  const [columnWidths, setColumnWidths] = useState<readonly number[]>(
    props.columns.map(c => c.defaultWidthPixels ?? DefaultColumnWidth),
  );

  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [rowHeights, setRowHeights] = useState<number[]>(
    props.data.map(() => 32),
  );
  const classes = useStyles({
    rowHeights,
  });

  useEffect(() => {
    rowRefs.current = rowRefs.current.slice(0, props.data.length);
  }, [props.data]);
  useEffect(() => {
    if (visibleColumns.length === 0) {
      setVisibleColumns(props.columns.map(() => true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns, visibleColumns]);
  useLayoutEffect(() => {
    // When the data shown changes, we refresh our data on the row heights
    // And also when visible columns are changed
    // This is needed to keep the actions cells for each row in sync
    // since they are part of a separate grid
    setRowHeights(
      rowRefs.current.map(r => r?.getBoundingClientRect().height ?? 0),
    );
  }, [props.data, visibleColumns]);

  const columnWidthChangeCallbacks = props.columns.map(
    (column, columnIndex) => {
      return (width: number) => {
        const minWidth =
          column.minimumWidthPixels ??
          column.defaultWidthPixels ??
          DefaultColumnWidth;
        const updatedWidth = Math.max(minWidth, width);
        // eslint-disable-next-line security/detect-object-injection
        const currentWidth = columnWidths[columnIndex];
        if (updatedWidth !== currentWidth) {
          setColumnWidths(update(columnIndex, updatedWidth, columnWidths));
        }
      };
    },
  );

  const onSortChange = (
    columnIndex: number,
    direction?: TableSortDirection,
  ) => {
    if (direction === undefined) {
      setSort(props.initialSort);
      props.onSortChange(props.initialSort);
    } else {
      setSort({columnIndex, direction});
      props.onSortChange({columnIndex, direction});
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <HeaderRow columnWidths={columnWidths} visibleColumns={visibleColumns}>
          {props.columns.map((c, i) =>
            // eslint-disable-next-line security/detect-object-injection
            visibleColumns[i] ? (
              <HeaderCell
                key={c.translationKey}
                {...c}
                columnIndex={i}
                sortDirection={
                  sort?.columnIndex === i ? sort.direction : undefined
                }
                isLastColumn={i === props.columns.length - 1}
                initialSort={props.initialSort}
                onSortDirectionChange={d => onSortChange(i, d)}
                // eslint-disable-next-line security/detect-object-injection
                onWidthChange={columnWidthChangeCallbacks[i]}
              />
            ) : (
              <span key={c.translationKey}></span>
            ),
          )}
        </HeaderRow>
        {props.isLoading && (
          <div className={classes.loadingSpinner}>
            <CircularProgress />
          </div>
        )}
        {props.data.length === 0 && !props.isLoading && (
          <NoResultsRow text={props.noResultsMessageTranslationKey ?? ''} />
        )}
        {props.data.length > 0 &&
          !props.isLoading &&
          props.data.map((row, rowIndex) => {
            const isOdd = rowIndex % 2 === 1;
            return (
              <Row
                key={rowIndex}
                isOdd={isOdd}
                columnWidths={columnWidths}
                visibleColumns={visibleColumns}
                isRowHovered={hoveredRowIndex === rowIndex}
                setIsRowHovered={isHovered =>
                  setHoveredRowIndex(isHovered ? rowIndex : null)
                }
                onClick={() => props.onRowClick?.(row, rowIndex)}
                // eslint-disable-next-line security/detect-object-injection
                ref={el => (rowRefs.current[rowIndex] = el)}
              >
                {props.columns.map((column, columnIndex) =>
                  // eslint-disable-next-line security/detect-object-injection
                  visibleColumns[columnIndex] ? (
                    <React.Fragment key={columnIndex}>
                      {(column.dataType === undefined ||
                        column.dataType === 'string') && (
                        <SingleLineTextCell
                          isOddRow={isOdd}
                          text={row[column.dataKey] as string}
                          isHighlighted={column.isHighlighted}
                        />
                      )}
                      {column.dataType === 'stringArray' && (
                        <MultiLineTextCell
                          isOddRow={isOdd}
                          lines={row[column.dataKey] as readonly string[]}
                          isHighlighted={column.isHighlighted}
                          setRowNotHovered={() =>
                            hoveredRowIndex === rowIndex
                              ? setHoveredRowIndex(null)
                              : undefined
                          }
                        />
                      )}
                      {column.dataType === 'timestampInfo' && (
                        <TimestampInfoCell
                          isOddRow={isOdd}
                          data={row[column.dataKey] as Readonly<TimestampInfo>}
                          isHighlighted={column.isHighlighted}
                          setRowNotHovered={() =>
                            hoveredRowIndex === rowIndex
                              ? setHoveredRowIndex(null)
                              : undefined
                          }
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <span key={column.translationKey}></span>
                  ),
                )}
              </Row>
            );
          })}
      </div>
      <div className={classes.actions}>
        <SettingsHeaderCell
          columns={props.columns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
        {props.data.map((row, rowIndex) => {
          const isOdd = rowIndex % 2 === 1;
          const actions =
            props.getActionsForRow === undefined
              ? props.actions
              : props.getActionsForRow(row, props.actions);
          const actionsWithOnClick = actions.map(a => ({
            ...a,
            onClick: () => a.onClick(row.id, row),
          }));
          return (
            <ActionsCell
              key={rowIndex}
              actionsMenuMode={props.actionsMenuMode ?? 'inline'}
              actionsMenuNoItemsTooltip={props.actionsMenuNoItemsTooltip}
              isOddRow={isOdd}
              actions={actionsWithOnClick}
              isRowHovered={hoveredRowIndex === rowIndex}
              // eslint-disable-next-line security/detect-object-injection
              rowHeight={rowHeights[rowIndex]}
              setIsRowHovered={isHovered =>
                setHoveredRowIndex(isHovered ? rowIndex : null)
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export default ValmetTable;
