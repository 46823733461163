import * as React from 'react';
import classNames from 'classnames';
import {makeStyles, Theme} from '@material-ui/core/styles';
import colors from '../../configs/colors';
import ButtonBase, {Props as BaseProps} from './ButtonBase';
const {white, black, blue2, grey14} = colors;
const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    backgroundColor: white,
    color: black,
    border: '1px solid #434446',
    '&:hover': {
      backgroundColor: grey14,
    },
    '&:focus': {
      border: `2px solid ${blue2}`,
    },
    '&:active': {
      backgroundColor: '#434446',
      color: white,
    },
    '&:disabled': {
      border: '1px solid #E0E1DD',
      color: '#E0E1DD',
    },
  },
}));

export interface Props extends BaseProps {
  /**
   * CSS class overrides
   */
  classes?: {
    root?: string;
  };
}

const GhostButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {classes, iconColor, ...rest} = props;
  const internalClasses = useStyles(props);

  return (
    <ButtonBase
      iconColor={iconColor ?? (props.isDisabled ? '#E0E1DD' : undefined)}
      classes={{root: classNames(internalClasses.root, classes?.root)}}
      ref={ref}
      {...rest}
    />
  );
});

export default GhostButton;
