import React from 'react';
import {useTranslation} from 'react-i18next';
import TextButton from '../Buttons/TextButton';

interface Props {
  isDisabled: boolean;
  onClick: () => void;
}

const PreviousButton = (props: Props) => {
  const {t} = useTranslation();
  return (
    <TextButton {...props} icon="arrow-left" text={t('pagination.previous')} />
  );
};

export default PreviousButton;
