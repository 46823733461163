import {Theme} from '@material-ui/core';

export default (theme: Theme) => {
  return {
    MuiMenu: {
      paper: {
        border: 'none',
        borderRadius: '0',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.153)',
      },
      list: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiMenuItem: {
      root: {
        borderBottom: '1px solid #F2F2F2',
        textAlign: 'right',
        padding: theme.spacing(1, 1.5),
        '&:last-of-type': {
          borderBottom: 'none',
        },
        '& > .MuiTypography-root': {
          flexGrow: 1,
          lineHeight: '15px',
        },
        '& > .icon': {
          marginLeft: theme.spacing(1.5),
          lineHeight: '15px',
        },
      },
    },
  };
};
