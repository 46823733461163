import {Theme} from '@material-ui/core';
import colors from '../colors';

export default (theme: Theme) => {
  return {
    MuiTooltip: {
      popper: {},
      tooltip: {
        color: colors.white,
        backgroundColor: colors.grey2,
        fontSize: '11px',
        lineHeight: '13px',
        borderRadius: '3px',
        padding: theme.spacing(1),
      },
      arrow: {
        color: colors.grey2,
      },
      tooltipPlacementBottom: {
        marginTop: `${theme.spacing(1)}px !important`,
        '& > .MuiTooltip-arrow': {
          marginTop: '-10px !important',
          marginLeft: '2px !important',
          width: '15px',
          height: '10.67px',
        },
      },
      tooltipPlacementLeft: {
        marginRight: `${theme.spacing(1)}px !important`,
        '& > .MuiTooltip-arrow': {
          marginRight: '-11px !important',
          marginTop: '2px !important',
          width: '10.67px !important',
          height: '15px !important',
        },
      },
      tooltipPlacementTop: {
        marginBottom: `${theme.spacing(1)}px !important`,
        '& > .MuiTooltip-arrow': {
          marginBottom: '-11px !important',
          marginLeft: '4px !important',
          width: '15px',
          height: '10.67px',
        },
      },
      tooltipPlacementRight: {
        marginLeft: `${theme.spacing(1)}px !important`,
        '& > .MuiTooltip-arrow': {
          marginLeft: '-11px !important',
          marginTop: '2px !important',
          width: '10.67px !important',
          height: '15px !important',
        },
      },
    },
  };
};
