import classNames from 'classnames';
import * as React from 'react';
import './Icon.css';
import IconNames from './IconNames';
import {
  Theme,
  makeStyles,
  createTheme,
  ThemeProvider,
  CssBaseline,
} from '@material-ui/core';
const eot = require('./assets/fonts/Valmet-Tiffany.eot');
const woff2 = require('./assets/fonts/Valmet-Tiffany.woff2');
const woff = require('./assets/fonts/Valmet-Tiffany.woff');
const ttf = require('./assets/fonts/Valmet-Tiffany.ttf');
const svg = require('./assets/fonts/Valmet-Tiffany.svg');

export interface Props {
  /**
   * The icon to show
   */
  icon: IconNames;
  size?: 'default' | 'medium' | 'large';
  color?: string;
  /**
   * If true, disables the pointer cursor normally shown when an onClick function is defined
   */
  disablePointerCursor?: boolean;
  tooltip?: string;
  className?: string;
  /**
   * Called when the icon is clicked;
   * if set the cursor will change to pointer when hovering over the icon
   * (unless you define disablePointerCursor)
   */
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    cursor: props =>
      props.onClick && !props.disablePointerCursor ? 'pointer' : 'unset',
  },
}));
const tiffany = {
  fontFamily: 'Valmet-Tiffany',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    src: url(${eot});
    src: url(${eot}) format('embedded-opentype'),
    url(${woff2}) format('woff2'),
    url(${ttf}) format('truetype'),
    url(${woff}) format('woff'),
    url(${svg}#Valmet-Tiffany) format('svg');
  `,
};

const theme = createTheme({
  typography: {
    fontFamily: 'Valmet-Tiffany',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [tiffany],
      },
    },
  },
});

const ValmetIcon = React.forwardRef<HTMLSpanElement, Props>((props, ref) => {
  const {
    size,
    className,
    color,
    onClick,
    tooltip,
    icon,
    disablePointerCursor,
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <span
        {...rest}
        className={classNames('icon', classes.root, className, {
          'medium-icon': size === 'medium',
          'large-icon': size === 'large',
        })}
        style={{color: color ?? 'unset'}}
        onClick={onClick}
        title={tooltip}
        ref={ref}
      >
        <i className={classNames(`icon-${icon}`)}></i>
      </span>
    </ThemeProvider>
  );
});

export default ValmetIcon;
