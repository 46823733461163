import * as React from 'react';
import classNames from 'classnames';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ValmetIcon from '../ValmetIcon';
import {IconNames} from '../ValmetIcon/IconNames';

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    justifyContent: props =>
      !!props.icon && !!props.text ? 'flex-start' : 'center',
    minWidth: props => (props.disableMinimumWidth ? 'unset' : '64px'),
    '& .icon': {
      marginRight: props => (props.iconPosition !== 'right' ? '4px' : '0'),
      marginLeft: props => (props.iconPosition === 'right' ? '4px' : '0'),
    },
  },
}));

export interface Props {
  /**
   * Text to show in the button
   */
  text?: string;
  isDisabled?: boolean;
  /**
   * Icon to display within the button
   */
  icon?: IconNames;
  /**
   * Color to set to the icon
   */
  iconColor?: string;
  /**
   * Where the given icon is placed (default left)
   */
  iconPosition?: 'left' | 'right';
  /**
   * Height of the button (default medium)
   */
  size?: 'large' | 'medium' | 'small';
  /**
   * CSS class overrides
   */
  classes?: {
    root?: string;
  };
  className?: string;
  /**
   * If true, removes the minimum width from the button
   */
  disableMinimumWidth?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonBase = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    text,
    icon,
    iconPosition,
    iconColor,
    classes,
    disableMinimumWidth,
    isDisabled,
    className,
    ...rest
  } = props;
  const internalClasses = useStyles(props);

  return (
    <Button
      className={classNames(internalClasses.root, classes?.root, className)}
      disabled={isDisabled}
      ref={ref}
      {...rest}
    >
      {icon && iconPosition !== 'right' && (
        <ValmetIcon icon={icon} color={iconColor} />
      )}
      {text && <span>{text}</span>}
      {icon && iconPosition === 'right' && (
        <ValmetIcon icon={icon} color={iconColor} />
      )}
    </Button>
  );
});

export default ButtonBase;
