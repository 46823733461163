import React, {useState} from 'react';
import {makeStyles, Theme, Popover, Box, Tooltip} from '@material-ui/core';

import MoreVert from '@material-ui/icons/MoreVert';
import colors from '../../configs/colors';
import {useTranslation} from 'react-i18next';
const {black, grey10} = colors;
export interface RowAction {
  translationKey: string;
  icon: React.ReactNode;
  color?: string;
  onClick: (rowId: string) => void;
}

interface Props {
  actions: readonly RowAction[];
  rowId: string;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    height: '25px',
    width: '45px',
    paddingLeft: '11px',
    display: 'flex',
    alignItems: 'center',
    '& > .icon': {
      transform: 'rotate(90deg)',
    },
  },
}));

export const ActionsCell = (props: Props) => {
  const {actions} = props;
  const classes = useStyles(props);
  const {t} = useTranslation();
  const [inlineAnchorEl, setInlineAnchorEl] =
    useState<(EventTarget & HTMLDivElement) | null>(null);

  const onMenuButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (actions.length === 0) {
      // There are no items in menu anyway
      return;
    }
    setInlineAnchorEl(e.currentTarget);
  };
  const tooltip =
    actions.length > 0
      ? t('table.tooltips.rowActions')
      : t('table.tooltips.rowActionsNoPermissions');

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Tooltip title={tooltip} placement="bottom" arrow>
        <div
          className={`${classes.root} inline-menu-row`}
          onClick={onMenuButtonClick}
          aria-controls="menu-row"
          aria-haspopup={actions.length > 0}
        >
          <MoreVert
            style={{
              color: actions.length === 0 ? grey10 : black,
              cursor: actions.length > 0 ? 'pointer' : 'initial',
            }}
          />
        </div>
      </Tooltip>

      <InlineMenu
        actions={props.actions}
        anchorEl={inlineAnchorEl}
        setAnchorEl={setInlineAnchorEl}
        rowId={props.rowId}
      />
    </Box>
  );
};

const useInlineMenuStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& > .icon': {
      cursor: 'pointer',
      marginLeft: theme.spacing(2),
    },
    '& > .icon:first-child': {
      marginLeft: theme.spacing(1),
    },
    minHeight: '35px',
  },
  paper: {
    //TODO move under iop-ui-comon/theme.ts
    '& > .MuiPaper-root': {
      transform: 'translateX(5px) !important',
      border: 0,
      boxShadow: '-2px 0px 3px #ccc;',
    },
  },
}));

const InlineMenu = ({
  actions,
  anchorEl,
  setAnchorEl,
  rowId,
}: {
  actions: readonly RowAction[];
  anchorEl: (EventTarget & HTMLDivElement) | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<(EventTarget & HTMLDivElement) | null>
  >;
  rowId: string;
}) => {
  const classes = useInlineMenuStyles();

  return (
    <Popover
      id="inline-menu-row"
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'center',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      className={classes.paper}
    >
      <div className={classes.root}>
        {actions.map(({translationKey, icon, onClick}) => (
          <div
            key={translationKey}
            className="icon"
            onClick={() => {
              onClick(rowId);
              setAnchorEl(null);
            }}
          >
            <Box display="flex" alignItems="center">
              {icon}
            </Box>
          </div>
        ))}
      </div>
    </Popover>
  );
};
