import React from 'react';
import {useTranslation} from 'react-i18next';
import DialogBase, {DialogButton} from './DialogBase';

export interface Props {
  isOpen: boolean;
  onResult: (confirmed: boolean) => void;
}

const FormCancelConfirmDialog = (props: Props) => {
  const {t} = useTranslation();

  const onClose = () => {
    props.onResult(false);
  };
  const onConfirm = () => {
    props.onResult(true);
  };
  const buttons: DialogButton[] = [
    {
      text: t('forms.cancelConfirmDialog.cancel'),
      type: 'default',
      onClick: onClose,
    },
    {
      text: t('forms.cancelConfirmDialog.closeWithoutSaving'),
      type: 'ghost',
      onClick: onConfirm,
    },
  ];

  return (
    <DialogBase
      headerText={t('forms.cancelConfirmDialog.header')}
      isOpen={props.isOpen}
      onClose={onClose}
      buttons={buttons}
    />
  );
};

export default FormCancelConfirmDialog;
