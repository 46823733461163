import {Box, makeStyles, Theme, Tooltip} from '@material-ui/core';
import * as React from 'react';
import colors from '../../configs/colors';

export interface EquipmentLevelIndicatorProps {
  // eqType: string; // Will be needed when CNG
  label: string;
  currentLevel?: number;
  capacity?: number;
  unit: string;
}

const useStyles = makeStyles<Theme, {}>(() => ({
  indicator: {
    display: 'flex',
    alignItems: 'center',
    justifyFlex: 'center',
    gap: '8px',

    backgroundColor: colors.grey15,
    color: colors.grey5,
    border: `1px solid ${colors.grey11}`,

    borderRadius: '15px',

    width: '220px',
    minWidth: 'fit-content',
    padding: '1px 10px',
  },

  label: {
    minWidth: '100px',
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  currentLevel: {
    minWidth: '50px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
}));

const useGaugeStyles = makeStyles<Theme, {gaugeLevel: number | null}>(() => ({
  gauge: {
    height: '18px',
    width: '50px',
    background: ({gaugeLevel}) =>
      gaugeLevel
        ? `linear-gradient(90deg, ${colors.blue3} ${gaugeLevel}%, ${colors.grey5} ${gaugeLevel}%)`
        : colors.grey5,
    border: `1px solid ${colors.grey5}`,
    color: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ValueGauge = ({gaugeLevel}: {gaugeLevel: number | null}) => {
  const classes = useGaugeStyles({gaugeLevel});
  return <Box className={classes.gauge}>{gaugeLevel === null && '?'}</Box>;
};

const EquipmentLevelIndicator = ({
  equipment,
}: {
  equipment: EquipmentLevelIndicatorProps;
}) => {
  const classes = useStyles();
  const gaugeLevel =
    equipment.currentLevel !== undefined && equipment.capacity !== undefined
      ? (equipment.currentLevel * 100) / equipment.capacity
      : null;

  return (
    <Tooltip title={equipment.label}>
      <Box className={classes.indicator}>
        <span className={classes.label}> {equipment.label} </span>
        <ValueGauge gaugeLevel={gaugeLevel} />
        <span className={classes.currentLevel}>
          {equipment.currentLevel} {equipment.unit}
        </span>
      </Box>
    </Tooltip>
  );
};

export default EquipmentLevelIndicator;
