import * as React from 'react';
import colors from '../../configs/colors';
import {makeStyles, Theme} from '@material-ui/core';
const {grey14, grey11} = colors;
interface Props {
  columnWidths: readonly number[];
  visibleColumns: readonly boolean[];
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    height: '44px',
    backgroundColor: grey14,
    borderTop: `1px solid ${grey11}`,
    borderBottom: `1px solid ${grey11}`,
    display: 'grid',
    gridTemplateRows: '42px',
    /* eslint-disable security/detect-object-injection */
    gridTemplateColumns: props =>
      props.columnWidths
        .map((w, i) => (props.visibleColumns[i] ? `${w}px` : '0px'))
        .join(' '),
    minWidth: props =>
      `${props.columnWidths
        .filter((_, i) => props.visibleColumns[i])
        .reduce((prev, current) => prev + current, 0)}px`,
    /* eslint-enable security/detect-object-injection */
  },
}));

const HeaderRow: React.FC<Props> = props => {
  const classes = useStyles(props);

  return <div className={classes.root}>{props.children}</div>;
};

export default HeaderRow;
