import muiButton from './MuiButton';
import muiForm from './MuiForm';
import muiTable from './MuiTable';
import muiDialog from './MuiDialog';
import muiDivider from './MuiDivider';
import muiAppBar from './MuiAppBar';
import muiToolbar from './MuiToolbar';
import muiInputBase from './MuiInputBase';
import muiFormHelperText from './MuiFormHelperText';
import muiMenu from './MuiMenu';
import muiAutocomplete from './MuiAutocomplete';
import muiTooltip from './MuiTooltip';
import muiRadio from './MuiRadio';
import muiChip from './MuiChip';
import muiCheckbox from './MuiCheckbox';
import muiPagination from './MuiPagination';
import {Theme} from '@material-ui/core';

export default (theme: Theme) => {
  const overrides = {
    ...muiForm(theme),
    ...muiButton(theme),
    ...muiTable(theme),
    ...muiDialog(theme),
    ...muiDivider(theme),
    ...muiAppBar(theme),
    ...muiToolbar(theme),
    ...muiInputBase(theme),
    ...muiFormHelperText(theme),
    ...muiMenu(theme),
    ...muiAutocomplete(theme),
    ...muiTooltip(theme),
    ...muiRadio(theme),
    ...muiChip(theme),
    ...muiCheckbox(theme),
    ...muiPagination(),
    MuiPopover: {
      paper: {
        border: '1px solid #2D2D2D',
      },
    },
    MuiCard: {
      root: {
        minHeight: '100%',
      },
    },
  };

  return {
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiInput: {
        // disableUnderline: true
      },
      MuiSelect: {
        // displayEmpty: true
      },
      MuiInputLabel: {
        // disableAnimation: true,
        // shrink: false
      },
      MuiTooltip: {
        arrow: true,
      },
      MuiRadio: {
        disableFocusRipple: true,
      },
    },
    overrides,
  };
};
