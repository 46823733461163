import colors from '../colors';

export default () => {
  return {
    MuiPaginationItem: {
      root: {
        background: colors.grey12,
        color: colors.grey4,
        fontWeight: 'bold',
        border: `1px solid ${colors.grey10}`,
      },
      page: {
        '&:hover': {
          backgroundColor: colors.grey11,
        },
        '&.Mui-selected': {
          backgroundColor: `${colors.white} !important`,
          color: `${colors.grey4} !important`,
          border: `1px solid ${colors.blue2}`,
        },
        '&.Mui-disabled': {
          border: `1px solid ${colors.grey10}`,
          backgroundColor: colors.grey10,
          color: colors.white,
        },
      },
    },
  };
};
