import * as React from 'react';
import {Option} from '../Menus/MultiSelectMenu';
import {TFilterTypeEnum} from './FilterBar';

export interface IFilterOption extends Option {
  filterId: string;
  type: TFilterTypeEnum;
}

export interface IActions {
  add: (options: IFilterOption[]) => void;
  up: (options: IFilterOption[]) => void;
  remove: (option: IFilterOption) => void;
  reset: () => void;
}

export interface Context extends IActions {
  selected: IFilterOption[];
}

export const FilterContext = React.createContext<Context>({
  add: () => {},
  up: () => {},
  remove: () => {},
  reset: () => {},
  selected: [],
});

export function useFilter(): Context {
  const context = React.useContext<Context>(FilterContext);
  if (!context) {
    throw new Error(`useFilter must be used within a FilterContextProvider`);
  }
  return context;
}
