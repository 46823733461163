import React from 'react';
import {Theme, makeStyles} from '@material-ui/core';
import IconNames from '../ValmetIcon/IconNames';
import colors from '../../configs/colors';
import TextButton from '../Buttons/TextButton';
const {blue2, white, grey14, grey9} = colors;
interface Props {
  text?: string;
  icon?: IconNames;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    minWidth: '36px',
    color: props =>
      props.icon !== undefined ? grey9 : props.isActive ? white : blue2,
    backgroundColor: props => (props.isActive ? blue2 : white),
    '&:hover': {
      backgroundColor: props => (props.isActive ? blue2 : grey14),
    },
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const PageButton = (props: Props) => {
  const classes = useStyles(props);
  return (
    <TextButton
      text={props.text}
      icon={props.icon}
      iconColor={
        props.icon !== undefined && !props.isDisabled ? grey9 : undefined
      }
      isDisabled={props.isDisabled}
      onClick={props.onClick}
      classes={{root: classes.root}}
    />
  );
};

export default PageButton;
