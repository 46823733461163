import React from 'react';
import {makeStyles, Theme, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import colors from '../../configs/colors';
const {grey15, grey4} = colors;
interface Props {
  translationKey: string;
}
const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    height: '32px',
    padding: '2px 10px',
    backgroundColor: grey15,
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '28px',
    color: grey4,
  },
}));

const ContextPanelContentHeader = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        {t(props.translationKey)}
      </Typography>
    </div>
  );
};

export default ContextPanelContentHeader;
