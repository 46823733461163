const colors = {
  white: '#FFFFFF',
  black: '#000000',

  grey0: '#1D1D1D',
  grey1: '#2D2D2D',
  grey2: '#333333',
  grey3: '#4C4D4F',
  grey4: '#666666',
  grey5: '#787878',
  grey6: '#8B8D8E',
  grey7: '#A3A5A7',
  grey8: '#B2B2B2',
  grey9: '#BDBDBD',
  grey10: '#C1C2C5',
  grey11: '#CCCCCC',
  grey12: '#DDDDDD',
  grey13: '#E5E5E5',
  grey14: '#ECECEC',
  grey15: '#F6F7F8',
  grey16: '#FDFDFD',

  blue1: '#3C4448',
  blue2: '#008ABA',
  blue3: '#00B4E4',
  blue4: '#8FCAE7',
  blue5: '#C2DEEA',
  blue6: '#004DB4',

  red1: '#C41230',
  red2: '#E70033',
  red3: '#F95A7A',
  red4: '#FF94AC',
  red5: '#F4E6E9',
  red6: '#FF0043',

  yellow1: '#F4AA00',
  yellow2: '#FCDC41',
  yellow3: '#FAE498',
  // There is no "Yellow 4" ¯\_(ツ)_/¯
  yellow5: '#F8FC60',
  yellow6: '#B69D34',

  brown1: '#766341',
  brown2: '#857352',
  brown3: '#D4BF96',
  brown4: '#DCCEAB',

  green1: '#50B948',
  green2: '#96D591',
  green3: '#DAE5CD',
  green4: '#EFF7EB',

  orange1: '#F89A41',
  pink1: '#E134E2',
};
export default colors;
