import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles, Theme, Typography, Popover} from '@material-ui/core';
import GhostButton from '../Buttons/GhostButton';
import colors from '../../configs/colors';
const {white} = colors;
const useStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    flexGrow: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  text: {
    lineHeight: '15px',
  },
  button: {
    alignSelf: 'flex-end',
  },
  popoverPaper: {
    border: 'none',
    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))',
    overflow: 'visible',
  },
  arrow: {
    position: 'absolute',
    left: '-5px',
    top: 'calc(50% - 5px)',
    width: '10px',
    height: '10px',
    backgroundColor: white,
    transform: 'rotate(45deg)',
  },
}));

const FullInfoPopover = (props: {
  anchorEl: Element | null;
  lines: readonly string[];
  onCloseClick: () => void;
}) => {
  const {t} = useTranslation();
  const classes = useStyles({});

  return (
    <Popover
      id="full-info-popover"
      getContentAnchorEl={null}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'center',
      }}
      open={Boolean(props.anchorEl)}
      classes={{paper: classes.popoverPaper}}
    >
      <div className={classes.root}>
        <div className={classes.textContainer}>
          {props.lines.map((l, i) => (
            <React.Fragment key={i}>
              <Typography className={classes.text}>{l}</Typography>
            </React.Fragment>
          ))}
        </div>
        <div className={classes.button}>
          <GhostButton
            onClick={props.onCloseClick}
            text={t('close')}
            size="small"
          />
        </div>
      </div>
      <div className={classes.arrow}></div>
    </Popover>
  );
};

export default FullInfoPopover;
