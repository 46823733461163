import {Theme} from '@material-ui/core';
import colors from '../colors';

export default (theme: Theme) => {
  return {
    MuiFormLabel: {
      root: {
        lineHeight: '20px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '13px',
        color: `${colors.grey3} !important`,
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: '5px',
      },
    },
    MuiFormControlLabel: {
      label: {
        lineHeight: '16px',
        color: colors.grey3,
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: theme.spacing(0.5),
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        color: '#8D8E8D',
      },
    },
  };
};
