import React, {useMemo} from 'react';
import {Column} from './types';
import {useTranslation} from 'react-i18next';
import MultiSelectMenu from '../Menus/MultiSelectMenu';

const ColumnSettingsPopover = (props: {
  anchorEl: Element | null;
  columns: readonly (Column & {visible: boolean})[];
  onVisibleColumnsChange: (visibleColumns: boolean[]) => void;
  onClose: () => void;
}) => {
  const {t} = useTranslation();
  const options = useMemo(
    () =>
      props.columns.map((c, i) => ({
        text: t(c.translationKey),
        value: i.toString(),
      })),
    [props.columns, t],
  );
  const selectedOptions = useMemo(
    () =>
      props.columns
        .map((c, i) => ({...c, i}))
        .filter(c => c.visible)
        .map(c => options[c.i]),
    [props.columns, options],
  );

  const onSelectedOptionsChange = (opts: {text: string; value: string}[]) => {
    props.onVisibleColumnsChange(
      props.columns.map((_, i) => opts.some(v => Number(v.value) === i)),
    );
  };

  return (
    <MultiSelectMenu
      anchorEl={props.anchorEl}
      placement="bottom-end"
      onClose={props.onClose}
      options={options}
      selectedOptions={selectedOptions}
      onSelectedOptionsChange={onSelectedOptionsChange}
      offset="0, 1"
    />
  );
};

export default ColumnSettingsPopover;
