import React from 'react';
import {useTranslation} from 'react-i18next';
import TextButton from '../Buttons/TextButton';

interface Props {
  isDisabled: boolean;
  onClick: () => void;
}

const NextButton = (props: Props) => {
  const {t} = useTranslation();
  return (
    <TextButton
      {...props}
      icon="arrow-right"
      iconPosition="right"
      text={t('pagination.next')}
    />
  );
};

export default NextButton;
