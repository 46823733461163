import {makeStyles, Theme} from '@material-ui/core';
import React from 'react';
import {ToastContainer as ToastifyContainer} from 'react-toastify';
import colors from '../../configs/colors';

const useStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    width: '328px',
  },
  toast: {
    minHeight: 0,
    padding: '10px 20px 12px 20px',
    cursor: 'initial',
    '&.Toastify__toast--success': {
      backgroundColor: theme.palette.success.main,
    },
    '&.Toastify__toast--info': {
      backgroundColor: theme.palette.info.main,
    },
    '&.Toastify__toast--error': {
      backgroundColor: colors.red3,
    },
  },
  progress: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ToastContainer = () => {
  const classes = useStyles({});
  return (
    <ToastifyContainer
      position="top-right"
      closeButton={false}
      closeOnClick={false}
      className={classes.root}
      toastClassName={classes.toast}
      progressClassName={classes.progress}
      autoClose={5000}
    />
  );
};

export default ToastContainer;
