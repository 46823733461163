import {Theme} from '@material-ui/core';
import colors from '../colors';
const {black, grey10, white} = colors;

export default (theme: Theme) => {
  return {
    MuiDialog: {
      paper: {
        width: '300px',
        backgroundColor: white,
        border: `1px solid ${grey10}`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: theme.spacing(5.5, 4, 2, 4),
        '& > .MuiTypography-root': {
          fontSize: '23px',
          lineHeight: '26px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          color: black,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: theme.spacing(0.5, 4, 0.5, 4),
        '& > p': {
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          hyphens: 'auto',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(2, 4, 4, 4),
        justifyContent: 'flex-start',
        '& > button': {
          flex: '1 1 auto',
        },
      },
    },
  };
};
