import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextButton from '../Buttons/TextButton';
import {Menu, MenuItem, Typography} from '@material-ui/core';
import {partial} from 'ramda';

interface Props {
  pageSize: number;
  pageSizes: readonly number[];
  onChange: (pageSize: number) => void;
}

const PageSizeSelector = (props: Props) => {
  const {t} = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] =
    useState<(EventTarget & HTMLButtonElement) | null>(null);

  const onPageSizeChange = (pageSize: number) => {
    setMenuAnchorEl(null);
    if (pageSize === props.pageSize) {
      return;
    }

    props.onChange(pageSize);
  };

  return (
    <>
      <TextButton
        onClick={e => setMenuAnchorEl(e.currentTarget)}
        icon="arrow-down"
        text={`${props.pageSize} ${t('pagination.perPage')}`}
      />
      <Menu
        disableAutoFocusItem
        id="page-size-selector"
        getContentAnchorEl={null}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {props.pageSizes.map(size => (
          <MenuItem key={size} onClick={partial(onPageSizeChange, [size])}>
            <Typography variant="body1">
              {`${size} ${t('pagination.perPage')}`}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PageSizeSelector;
