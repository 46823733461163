import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Box} from '@material-ui/core';
import {useTheme} from '@material-ui/core';

export interface SortArrowsProps {
  disableSortBy: boolean;
  isSorted: boolean;
  isSortedDesc: boolean;
}

const SortArrowDefault = () => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Box position="relative" top={theme.spacing(1.5)}>
        <ExpandLessIcon fontSize="small" />
      </Box>
      <Box position="relative" bottom={theme.spacing(0.5)}>
        <ExpandMoreIcon fontSize="small" />
      </Box>
    </Box>
  );
};

const SortArrowTop = () => <ExpandLessIcon fontSize="small" />;

const SortArrowBottom = () => <ExpandMoreIcon fontSize="small" />;

const SortArrows = ({disableSortBy, isSorted, isSortedDesc}: SortArrowsProps) =>
  !disableSortBy ? (
    <Box display="flex" justifyContent="flex-end">
      {isSorted ? (
        isSortedDesc ? (
          <SortArrowBottom />
        ) : (
          <SortArrowTop />
        )
      ) : (
        <SortArrowDefault />
      )}
    </Box>
  ) : null;

export default SortArrows;
