import * as React from 'react';
import classNames from 'classnames';
import {makeStyles, Theme} from '@material-ui/core/styles';
import colors from '../../configs/colors';
import ButtonBase, {Props as BaseProps} from './ButtonBase';
const {white, black, blue2, grey14, grey12, grey8} = colors;
const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    backgroundColor: white,
    color: blue2,
    border: `2px solid ${white}`,
    '&:hover': {
      backgroundColor: grey14,
      border: `2px solid ${grey14}`,
      transform: 'none',
    },
    '&:focus': {
      border: '2px solid #434446',
    },
    '&:active': {
      backgroundColor: grey12,
    },
    '&:disabled': {
      color: grey8,
    },
    '& .icon': {
      color: black,
    },
    '&:disabled .icon': {
      color: grey8,
    },
  },
}));

export interface Props extends BaseProps {
  /**
   * CSS class overrides
   */
  classes?: {
    root?: string;
  };
}

const TextButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {classes, iconColor, ...rest} = props;
  const internalClasses = useStyles(props);

  return (
    <ButtonBase
      iconColor={iconColor ?? (props.isDisabled ? grey8 : undefined)}
      classes={{root: classNames(internalClasses.root, classes?.root)}}
      ref={ref}
      {...rest}
    />
  );
});

export default TextButton;
