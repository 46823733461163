import * as React from 'react';
import classNames from 'classnames';
import {makeStyles, Theme} from '@material-ui/core/styles';
import colors from '../../configs/colors';
import ButtonBase, {Props as BaseProps} from './ButtonBase';
const {white, grey11, grey3, blue3, grey0, grey6} = colors;
const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    backgroundColor: grey3,
    border: `2px solid ${grey3}`,
    color: white,
    '&:hover': {
      backgroundColor: grey0,
      border: `2px solid ${grey0}`,
    },
    '&:focus': {
      border: `2px solid ${blue3}`,
    },
    '&:active': {
      backgroundColor: grey0,
    },
    '&:disabled': {
      border: `2px solid ${grey6}`,
      backgroundColor: grey6,
      color: grey11,
    },
  },
}));

export interface Props extends BaseProps {
  /**
   * CSS class overrides
   */
  classes?: {
    root?: string;
  };
}

const DefaultButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {classes, iconColor, ...rest} = props;
    const internalClasses = useStyles(props);

    return (
      <ButtonBase
        iconColor={iconColor ?? (props.isDisabled ? grey11 : undefined)}
        classes={{
          root: classNames(internalClasses.root, classes?.root),
        }}
        ref={ref}
        {...rest}
      />
    );
  },
);

export default DefaultButton;
