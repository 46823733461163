import * as React from 'react';
import {Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export interface Props {
  translationKey: string;
}

const PageHeading: React.FC<Props> = props => {
  const {t} = useTranslation();

  return <Typography variant="h2">{t(props.translationKey)}</Typography>;
};

export default PageHeading;
