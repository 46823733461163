import React, {useMemo} from 'react';
import {Theme, makeStyles} from '@material-ui/core';
import PageButton from './PageButton';
import {range} from 'ramda';
import ValmetIcon from '../ValmetIcon';
import colors from '../../configs/colors';
const {grey9} = colors;
interface Props {
  page: number;
  totalPages: number;
  visiblePages: number;
  onChange: (page: number) => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    display: 'flex',
  },
}));

const getVisiblePages = (
  currentPage: number,
  totalPages: number,
  visiblePages: number,
): readonly number[] => {
  if (totalPages <= 0 || currentPage < 0 || currentPage >= totalPages) {
    // Invalid props, we can't show pages
    return [];
  }

  if (totalPages <= visiblePages) {
    // Show all pages if they fit within visiblePages
    return range(0, totalPages);
  }

  // Calculate size of "edges", i.e. areas at left and right end
  // that have different rules for what pages are shown than
  // in between them
  // Examples:
  // 5 visible pages, both edges are 3
  // 6 visible pages, left is 4 and right 3 (left gets additional page if visiblePages is even)
  // Note the edges will not overlap even though left edge size + right edge size > visible pages (by 1 at most)
  // We have more pages here than can be visible
  const leftEdgeSize = Math.floor(visiblePages / 2) + 1;
  const rightEdgeSize = Math.ceil(visiblePages / 2);

  if (currentPage < leftEdgeSize) {
    // We are on left edge, we show pages:
    // 0 -> visible pages - 2, and last page
    // -2 because we need to show last page + ellipsis
    // Example:
    // Total pages = 10, current page = 0, visible pages = 5
    // 0 -> 3 and last page shown (1, 2, 3, 10)
    // The range is exclusive at end so index 3 (aka page 4) is not shown
    return [...range(0, visiblePages - 2), totalPages - 1];
  }

  if (currentPage >= totalPages - rightEdgeSize) {
    // We are on right edge, we show pages:
    // First page and total pages - visible pages + 2 -> total pages
    // 2 again because we need first page + ellipsis
    // Example:
    // Total pages = 10, current page = 9, visible pages = 5
    // first page and 7 -> 10 shown (1, 8, 9, 10)
    // The range is exclusive at end so index 10 (aka page 11) is not shown
    return [0, ...range(totalPages - visiblePages + 2, totalPages)];
  }

  // We are between the edges, here we always show first, current and last page
  // as well as pages around the current page if possible

  // We need to take 5 for first, ellipsis, current, ellipsis and last
  // So the number of additional pages visible around current is:
  const middleVisibleAdditionalPages = visiblePages - 5;
  // We divide the additional pages by 2 to get their number on one side
  // It is rounded up for the left side so we prioritize it
  // Then we go back from the current page by that amount to get the start index
  const middleRangeStart =
    currentPage - Math.ceil(middleVisibleAdditionalPages / 2);
  // The range ends naturally at start + count, but we have to add one more
  // because we actually need to include that last index too
  const middleRangeEnd = middleRangeStart + middleVisibleAdditionalPages + 1;
  return [0, ...range(middleRangeStart, middleRangeEnd), totalPages - 1];
};

const PageSelector = (props: Props) => {
  const classes = useStyles(props);
  const visiblePages = useMemo(() => {
    return getVisiblePages(props.page, props.totalPages, props.visiblePages);
  }, [props.page, props.totalPages, props.visiblePages]);

  if (
    props.totalPages <= 0 ||
    props.page < 0 ||
    props.page >= props.totalPages
  ) {
    return null;
  }

  const isFirstPage = props.page === 0;
  const isLastPage = props.page === props.totalPages - 1;

  const onPreviousClick = () => {
    if (props.page === 0) {
      return;
    }

    props.onChange(props.page - 1);
  };
  const onNextClick = () => {
    if (props.page === props.totalPages - 1) {
      return;
    }

    props.onChange(props.page + 1);
  };

  return (
    <div className={classes.root}>
      <PageButton
        icon="arrow-left"
        isDisabled={isFirstPage}
        onClick={onPreviousClick}
      />
      {visiblePages.map((page, i) => {
        let renderEllipsis = false;
        if (i > 0) {
          const previousPage = visiblePages[i - 1];
          if (page - previousPage > 1) {
            renderEllipsis = true;
          }
        }
        return (
          <React.Fragment key={page}>
            {renderEllipsis && <Ellipsis />}
            <PageButton
              key={page}
              text={(page + 1).toFixed(0)}
              onClick={props.onChange.bind(null, page)}
              isActive={page === props.page}
            />
          </React.Fragment>
        );
      })}
      <PageButton
        icon="arrow-right"
        isDisabled={isLastPage}
        onClick={onNextClick}
      />
    </div>
  );
};

export default PageSelector;

const useEllipsisStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    width: '36px',
    height: '36px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: grey9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Ellipsis = () => {
  const classes = useEllipsisStyles({});
  return (
    <div className={classes.root}>
      <ValmetIcon icon="more" color={grey9} />
    </div>
  );
};
