import {Box, makeStyles, Theme} from '@material-ui/core';
import * as React from 'react';
import colors from '../../configs/colors';

export interface EndpointDetailLevelIndicatorProps {
  label?: string;
  level?: number | null;
  capacity?: number | null;
  unit?: string | null;
}

interface StyleProps {
  gaugeLevel?: number;
}

const useStyles = makeStyles<Theme, {}>(() => ({
  indicator: {
    display: 'flex',
    flexDirection: 'column',

    color: colors.grey5,

    width: '200px',
    minWidth: 'fit-content',
  },
}));

const useGaugeStyles = makeStyles<Theme, StyleProps>(() => ({
  gauge: {
    height: '20px',
    width: 'inherit',
    borderRadius: '5px',
    background: ({gaugeLevel}) =>
      gaugeLevel
        ? `linear-gradient(90deg, ${colors.green1} ${gaugeLevel}%, ${colors.grey15} ${gaugeLevel}%)`
        : colors.grey15,
    border: `1px solid ${colors.grey10}`,
  },
}));

const ValueGauge = (gaugeLevel: {gaugeLevel?: number}) => {
  const classes = useGaugeStyles(gaugeLevel);
  return <Box className={classes.gauge} />;
};

const EndpointDetailLevelIndicator = ({
  endpoint,
}: {
  endpoint: EndpointDetailLevelIndicatorProps;
}) => {
  const classes = useStyles();
  const gaugeLevel =
    endpoint.level && endpoint.capacity
      ? Math.floor((endpoint.level * 100) / endpoint.capacity)
      : undefined;

  return (
    <Box className={classes.indicator}>
      <span>
        <b> {endpoint.label || '-'}: </b> {endpoint.level || '-'}
        {} {endpoint.unit} {gaugeLevel ? `(${gaugeLevel}%)` : ''}
      </span>
      <ValueGauge gaugeLevel={gaugeLevel} />
    </Box>
  );
};

export default EndpointDetailLevelIndicator;
