import {Theme} from '@material-ui/core';

export default (_: Theme) => {
  return {
    MuiToolbar: {
      gutters: {
        paddingLeft: '25px !important',
        paddingRight: '0 !important',
      },
    },
  };
};
