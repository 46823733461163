import * as React from 'react';
import {Theme, makeStyles} from '@material-ui/core';

export type InputWidth = 'default' | 'medium' | 'small' | 'full';

interface Props {
  hasHelpText: boolean;
  hasErrorText: boolean;
  disableMargin?: boolean;
  hideOverflow?: boolean;
  width?: InputWidth;
}

export const Widths: Record<InputWidth, string> = {
  default: '250px',
  medium: '120px',
  small: '86px',
  full: '100%',
};

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    marginBottom: props =>
      props.disableMargin
        ? '0'
        : props.hasHelpText || props.hasErrorText
        ? theme.spacing(1)
        : theme.spacing(2),
    width: props => Widths[props.width ?? 'default'],
    maxWidth: '100%',
    overflow: props => (props.hideOverflow ? 'hidden' : undefined),
    '&:last-child': {
      marginBottom: '0',
    },
  },
}));

const InputWrapper: React.FC<Props> = props => {
  const classes = useStyles(props);
  return <div className={classes.root}>{props.children}</div>;
};

export default InputWrapper;
