import React, {useRef} from 'react';
import {Theme, makeStyles, Box} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import colors from '../../configs/colors';
import {Chip} from '../Inputs/ValmetMultiSelect';
import {FilterBar, IFilterBarItem} from './FilterBar';
import ValmetIcon from '../ValmetIcon';
import {produce} from 'immer';
import {
  Context,
  FilterContext,
  IFilterOption,
  useFilter,
  IActions,
} from './context';

const {grey15, grey12, grey3, blue2} = colors;

export interface IFilterState {
  text: string;
  selected: IFilterOption[];
}
export interface IFilter {
  state: IFilterState;
  actions: IActions;
}
export interface IFilterState {
  text: string;
  selected: IFilterOption[];
}
export interface IFilter {
  state: IFilterState;
  actions: IActions;
}
export interface Props {
  /**
   * The current text filter value
   */
  filterText: string;
  /**
   * Called when the text filter value should change
   */
  onFilterTextChange?: (filterText: string) => void;
  onFilterChange?: (filter: IFilter) => void;
  scheme?: IFilterBarItem[];
  selected?: IFilterOption[];
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  container: {
    backgroundColor: grey15,
    border: `1px solid ${grey12}`,
    '&:hover': {
      border: `1px solid ${grey3}`,
    },
    '&:focus-within': {
      border: `1px solid ${blue2}`,
      outline: `1px solid ${blue2}`,
    },
  },
  root: {
    width: '100%',
    margin: 0,
    minHeight: '36px',
    display: 'flex',
    flexWrap: 'wrap',
  },

  input: {
    flex: 1,
    border: 'none',
    margin: `${theme.spacing(0.5)}px 0`,
    height: '24px',
    background: 'none',
    '&:hover': {
      border: 'none',
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    },

    '&::placeholder': {
      color: grey3,
      opacity: 1,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '14px',
    },
  },

  iconFilterWrapper: {
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(2)}px`,
  },
  iconClose: {
    fontSize: theme.spacing(2.25),
    lineHeight: `${theme.spacing(2.25)}px`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const ADD = 'ADD';
const REMOVE = 'REMOVE';
const UP = 'UP';
const RESET = 'RESET';
function reducer(
  state: {selected: IFilterOption[]},
  action:
    | {type: typeof ADD; payload: IFilterOption[]}
    | {type: typeof UP; payload: IFilterOption[]}
    | {type: typeof RESET}
    | {type: typeof REMOVE; payload: IFilterOption},
) {
  switch (action.type) {
    case RESET:
      return produce(state, draft => {
        draft.selected = [];
      });
    case UP:
      return produce(state, draft => {
        draft.selected = action.payload;
      });
    case ADD:
      return produce(state, draft => {
        action.payload.forEach(option => {
          if (
            !draft.selected.find(
              item =>
                item.value === option.value &&
                item.filterId === option.filterId,
            )
          ) {
            draft.selected.push(option);
          }
        });
      });
    case REMOVE:
      return produce(state, draft => {
        const index = draft.selected.findIndex(
          item => item.value === action.payload.value,
        );
        draft.selected.splice(index, 1);
      });
    default:
      return state;
  }
}

const Filter = (props: Props) => {
  const classes = useStyles(props);
  const {t} = useTranslation();
  const ref = useRef<HTMLInputElement>(null!);
  const {filterText, onFilterTextChange, onFilterChange, scheme} = props;

  const [{selected}, dispatch] = React.useReducer(reducer, {
    selected: props.selected ?? [],
  });
  const handleOnFilterTextChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onFilterTextChange && onFilterTextChange(ev.target.value);
  };
  const onFilterIconClick = () => {
    // Clicking the filter icon would do nothing
    // so instead we focus the field on click
    ref.current.focus();
  };
  const onClearFilterTextClick = () => {
    if (!filterText && !selected.length) {
      // No point clearing anything if there is nothing to clear
      return;
    }

    dispatch({type: RESET});
    onFilterTextChange && onFilterTextChange('');
  };

  const value: Context = React.useMemo(() => {
    return {
      selected,
      up: (options: IFilterOption[]) => {
        dispatch({type: UP, payload: options});
      },
      add: (options: IFilterOption[]) => {
        dispatch({type: ADD, payload: options});
      },
      remove: (option: IFilterOption) => {
        dispatch({type: REMOVE, payload: option});
      },
      reset: () => {
        dispatch({type: RESET});
      },
    };
  }, [dispatch, selected]);

  React.useEffect(() => {
    const {selected, ...actions} = value;
    onFilterChange &&
      onFilterChange({state: {text: filterText, selected}, actions});
  }, [selected, filterText, onFilterChange, value]);
  return (
    <FilterContext.Provider value={value}>
      <Box className={classes.container}>
        <Box className={classes.root}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" alignItems="center" flexGrow="2">
              <Box className={classes.iconFilterWrapper} marginX={1}>
                <ValmetIcon
                  icon="add-filter"
                  onClick={onFilterIconClick}
                  disablePointerCursor
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                flexGrow={2}
              >
                <ChipsGroup />
                {onFilterTextChange && (
                  <input
                    value={filterText}
                    ref={ref}
                    onChange={handleOnFilterTextChange}
                    placeholder={t('filter.placeholder')}
                    className={classes.input}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.iconClose} paddingX={1}>
              <ValmetIcon
                icon="close-circle"
                onClick={onClearFilterTextClick}
                disablePointerCursor={!filterText}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {scheme && <FilterBar scheme={scheme} />}
    </FilterContext.Provider>
  );
};
function ChipsGroup() {
  const {selected, remove} = useFilter();
  return (
    <>
      {selected.map(option => {
        return (
          <Box mr={1} marginY={0.5} key={`${option.filterId}-${option.value}`}>
            <Chip
              option={option}
              onRemove={() => {
                remove(option);
              }}
            />
          </Box>
        );
      })}
    </>
  );
}

export default Filter;
