import {makeStyles, Theme, Typography} from '@material-ui/core';
import React, {useCallback} from 'react';
import colors from '../../configs/colors';
import ValmetIcon from '../ValmetIcon';
import IconNames from '../ValmetIcon/IconNames';
import GhostButton from '../Buttons/GhostButton';
import {toast, ToastOptions} from 'react-toastify';

interface NotificationBodyBaseProps {
  icon: IconNames;
  text: string;
  buttons?: readonly {text: string; onClick: () => void}[];
  closeToast?: () => void;
}

const useNotificationBodyBaseStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    display: 'grid',
    grid: 'auto auto / auto 1fr auto',
  },
  icon: {
    margin: '3px 23px 0 3px',
  },
  text: {
    color: colors.white,
    fontWeight: 700,
    wordBreak: 'break-word',
  },
  closeButton: {
    margin: '3px 3px 0 23px',
  },
  additionalButtonContainer: {
    gridColumnStart: 1,
    gridColumnEnd: 4,
    justifySelf: 'end',
    marginTop: theme.spacing(1),
  },
  additionalButton: {
    backgroundColor: 'transparent',
    color: colors.white,
    borderColor: colors.white,
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent',
      color: colors.white,
      borderColor: colors.white,
    },
    '&+&': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const NotificationBodyBase = (props: NotificationBodyBaseProps) => {
  const classes = useNotificationBodyBaseStyles({});
  const closeToast = props.closeToast;
  const onClose = useCallback(() => {
    if (!!closeToast) {
      closeToast();
    }
  }, [closeToast]);

  return (
    <div className={classes.root}>
      <ValmetIcon
        icon={props.icon}
        size="medium"
        color={colors.white}
        className={classes.icon}
      />
      <Typography className={classes.text}>{props.text}</Typography>
      <ValmetIcon
        icon="close"
        size="medium"
        color={colors.white}
        className={classes.closeButton}
        onClick={onClose}
      />
      {props.buttons !== undefined && props.buttons.length > 0 && (
        <div className={classes.additionalButtonContainer}>
          {props.buttons.map(b => (
            <GhostButton
              key={b.text}
              onClick={b.onClick}
              text={b.text}
              size="small"
              className={classes.additionalButton}
            />
          ))}
        </div>
      )}
    </div>
  );
};

type GeneralNotificationBodyProps = Omit<NotificationBodyBaseProps, 'icon'>;

const SuccessNotificationBody = (props: GeneralNotificationBodyProps) => {
  return <NotificationBodyBase {...props} icon="check" />;
};
const ErrorNotificationBody = (props: GeneralNotificationBodyProps) => {
  return <NotificationBodyBase {...props} icon="dialog-error" />;
};
const InformationNotificationBody = (props: GeneralNotificationBodyProps) => {
  return <NotificationBodyBase {...props} icon="more-info" />;
};

export const successNotification = (
  text: string,
  buttons?: GeneralNotificationBodyProps['buttons'],
  options?: ToastOptions,
) => {
  return toast.success(
    <SuccessNotificationBody text={text} buttons={buttons} />,
    options,
  );
};

export const errorNotification = (
  text: string,
  buttons?: GeneralNotificationBodyProps['buttons'],
  options?: ToastOptions,
) => {
  return toast.error(
    <ErrorNotificationBody text={text} buttons={buttons} />,
    options,
  );
};

export const infoNotification = (
  text: string,
  buttons?: GeneralNotificationBodyProps['buttons'],
  options?: ToastOptions,
) => {
  return toast.info(
    <InformationNotificationBody text={text} buttons={buttons} />,
    options,
  );
};

export const closeNotification = (toastId: string | number) => {
  toast.dismiss(toastId);
};
