import colors from '../../configs/colors';
import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    fontStyle: 'normal',
    fontFamily: 'Arial, sans-serif',
  },
  table: {
    borderBottom: `1px solid ${colors.grey12}`,
    minWidth: '100%',
    background: '#e9e9e9',
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
      height: theme.spacing(1),
    },

    '&::-webkit-scrollbar-track': {
      background: colors.grey12,
    },

    '&::-webkit-scrollbar-thumb': {
      background: colors.grey8,
    },
  },
  thead: {
    /* These styles are required for a scrollable body to align with the header properly */
    position: 'sticky',
    zIndex: 1,
    width: 'fit-content',
    top: 0,
  },
  tbody: {
    /* These styles are required for a scrollable table body */
    overflowY: 'scroll',
    height: `${theme.spacing(31.25)}px`,
    position: 'relative',
    zIndex: 0,
    background: `${colors.white}`,
  },
  body: {
    position: 'relative',
    zIndex: 0,
  },
  trWrapper: {
    background: colors.white,
    '&:nth-of-type(even)': {
      background: colors.grey15,
    },
  },
  trWrapperSubTable: {
    background: colors.grey15,
    '&:nth-of-type(even)': {
      background: colors.white,
    },
  },
  tr: {
    textDecoration: 'none',
    minHeight: theme.spacing(3.5),
    '&:hover': {
      background: '#d9f4fb',
      cursor: 'pointer',
      '&> div': {
        '&:not([role="columnheader"])': {
          background: '#d9f4fb',
        },
      },
    },
  },
  selectedRow: {
    background: '#b3e9f7 !important',
    '&:hover': {
      background: '#b3e9f7',
      cursor: 'pointer',
      '&> div': {
        '&:not([role="columnheader"])': {
          background: '#b3e9f7',
        },
      },
    },
  },
  subTr: {
    '&>div': {},
  },
  th: {
    borderRight: `${theme.spacing(0.125)}px solid ${colors.white}`,
    backgroundColor: colors.grey12,
    overflow: 'hidden',
    position: 'relative',
    color: colors.grey4,
    fontWeight: 'bold',
    height: theme.spacing(3.5),
    padding: `0 ${theme.spacing(0.625)}px`,
    display: 'flex !important',
    alignItems: 'center',
    '&[data-sticky-first-right-td="true"]': {
      borderLeft: `1px solid ${colors.grey5}`,
    },
    '&[data-sticky-last-left-td="true"]': {
      boxShadow: `1px 0px 0px ${colors.grey5} !important`,
      borderRight: 'none',
    },
  },
  thSelect: {
    display: 'inline-block !important',
    '>': {
      height: '100%',
    },
  },
  td: {
    padding: `0 ${theme.spacing(0.625)}px`,
    borderTop: `1px solid ${colors.grey12}`,
    borderRight: `1px solid ${colors.grey12}`,
    backgroundColor: 'inherit',
    position: 'relative',
    overflow: 'hidden',
    color: colors.grey2,
    '&[data-sticky-first-right-td="true"]': {
      borderLeft: `1px solid ${colors.grey5}`,
    },
    '&[data-sticky-last-left-td="true"]': {
      boxShadow: `1px 0px 0px ${colors.grey5} !important`,
    },
    '&:not([data-sticky-td="true"])': {
      flexGrow: 1,
    },
  },
  lastSub: {
    marginBottom: `${theme.spacing(1.875)}px`,
  },
  resizer: {
    display: 'inline-block',
    background: colors.grey12,
    width: theme.spacing(0.25),
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(50%)',
    zIndex: 1,
    /* prevents from scrolling while dragging on touch devices */
    touchAction: 'none',
  },
  isResizing: {
    width: theme.spacing(0.625),
    background: colors.grey11,
  },

  header: {
    position: 'sticky',
    zIndex: 1,
    width: 'fit-content',
    minWidth: '100%',
    top: 0,
  },
  sticky: {
    overflow: 'auto',
  },
}));
