import {Box, makeStyles, Theme, Tooltip} from '@material-ui/core';
import * as React from 'react';
import colors from '../../configs/colors';
import ValmetIcon from '../ValmetIcon';

export interface EndpointDetailTagValueProps {
  label: string;
  value?: number;
  warningMax?: number;
  warningMin?: number;
  alarmMax?: number;
  alarmMin?: number;
  valueQualityIssue?: string;
  unit?: string;
}

const useStyles = makeStyles<Theme, {flat?: boolean}>(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: ({flat}) => (flat ? 'row' : 'column'),
    alignItems: ({flat}) => (flat ? 'center' : undefined),
    justifyContent: ({flat}) => (flat ? 'space-between' : undefined),
    gap: ({flat}) => (flat ? '5px' : undefined),
    color: colors.grey5,

    width: ({flat}) => (flat ? '230px' : '130px'),
    minWidth: 'fit-content',
    fontFamily: 'Arial, sans-serif',
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: '16px',
    fontSize: '12px',
  },
  valuesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: ({flat}) => (flat ? '5px' : undefined),
    width: ({flat}) => (flat ? undefined : '100%'),
  },
  tooltip: {
    padding: '0',
    '& p': {
      margin: '2px',
    },
  },
  valueQualityIssue: {
    backgroundColor: colors.pink1,
    borderRadius: '1px',
  },
}));

const ValueIcon = ({
  value,
  warningMax,
  warningMin,
  alarmMax,
  alarmMin,
}: {
  value?: number;
  warningMax?: number;
  warningMin?: number;
  alarmMax?: number;
  alarmMin?: number;
}) => {
  const classes = useStyles({flat: false});
  if (!value) return null;

  const Icon = () => {
    if (alarmMax && value >= alarmMax)
      return <ValmetIcon icon="arrow-up" color={colors.red2} />;

    if (warningMax && value >= warningMax)
      return <ValmetIcon icon="arrow-up" color={colors.yellow2} />;

    if (alarmMin && value <= alarmMin)
      return <ValmetIcon icon="arrow-down" color={colors.blue2} />;

    if (warningMin && value <= warningMin)
      return <ValmetIcon icon="arrow-down" color={colors.blue5} />;

    return <ValmetIcon icon="check" color={colors.green1} />;
  };

  const tooltip = (
    <span className={classes.tooltip}>
      <p>Min alarm: {alarmMin || 'none'}</p>
      <p>Min warning: {warningMin || 'none'}</p>
      <p>Max warning: {warningMax || 'none'}</p>
      <p>Max alarm: {alarmMax || 'none'}</p>
    </span>
  );

  return <Tooltip title={tooltip}>{Icon()}</Tooltip>;
};

const SignalQualityIcon = ({
  valueQualityIssue,
}: {
  valueQualityIssue?: string;
}) => {
  const classes = useStyles({flat: false});

  if (!valueQualityIssue) return null;

  return (
    <Tooltip title={valueQualityIssue}>
      <ValmetIcon
        className={classes.valueQualityIssue}
        icon="flash"
        color={colors.white}
      />
    </Tooltip>
  );
};

const EndpointDetailTagValue = ({
  tag,
  flat,
}: {
  tag: EndpointDetailTagValueProps;
  flat?: boolean;
}) => {
  const classes = useStyles({flat: flat});

  return (
    <Box className={classes.wrapper}>
      <span className={classes.label}> {tag.label} </span>
      <Box className={classes.valuesWrapper}>
        <span>
          <ValueIcon
            value={tag.value}
            warningMax={tag.warningMax}
            warningMin={tag.warningMin}
            alarmMax={tag.alarmMax}
            alarmMin={tag.alarmMin}
          />
          {tag.value ? Math.round(tag.value * 100) / 100 : '-'} {tag.unit}
        </span>
        <SignalQualityIcon valueQualityIssue={tag.valueQualityIssue} />
      </Box>
    </Box>
  );
};

export default EndpointDetailTagValue;
