import {Box, InputLabel, makeStyles, Theme, Tooltip} from '@material-ui/core';
import * as React from 'react';
import colors from '../../configs/colors';

export interface SwitchProps {
  label: string;
  checked: boolean;
  onChange: () => void | null;
  isDisable?: boolean;
  isDisableToolTip?: string;
}

export interface StyleProps {
  checked: boolean;
  isDisable?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  switchLayout: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '1px 10px',
  },
  switch: {
    position: 'relative',
    cursor: ({isDisable}) => (isDisable ? 'default' : 'pointer'),
    opacity: ({isDisable}) => (isDisable ? '0.5' : undefined),
  },
  switchTrack: {
    width: '80px',
    height: '15px',
    backgroundColor: ({checked, isDisable}) =>
      isDisable || !checked ? colors.grey13 : colors.blue5,
    borderRadius: '5px',
    transition: 'background-color 1s',
  },
  switchThumb: {
    position: 'absolute',
    top: 0,
    left: ({checked, isDisable}) => (isDisable || !checked ? 0 : 45),
    width: '35px',
    height: '15px',
    backgroundColor: ({checked, isDisable}) =>
      isDisable || !checked ? colors.grey10 : colors.blue3,
    borderRadius: '5px',
    transition: 'left 0.5s, background-color 1s',
  },
}));

const Switch = ({
  label,
  checked,
  onChange,
  isDisable,
  isDisableToolTip,
}: SwitchProps) => {
  const classes = useStyles({checked, isDisable});

  return (
    <Box className={classes.switchLayout}>
      <Tooltip title={isDisable ? isDisableToolTip || '' : ''}>
        <Box
          className={classes.switch}
          onClick={() => !isDisable && onChange()}
        >
          <Box className={classes.switchTrack}> </Box>
          <Box className={classes.switchThumb}></Box>
        </Box>
      </Tooltip>

      <InputLabel style={{margin: '0', lineHeight: '14px'}}>{label}</InputLabel>
    </Box>
  );
};

export default Switch;
