import {Theme} from '@material-ui/core';
import colors from '../colors';
const {grey1} = colors;
export default (_: Theme) => {
  return {
    MuiAppBar: {
      root: {
        height: '70px',
        minHeight: '70px',
        maxHeight: '70px',
      },
      colorPrimary: {
        backgroundColor: grey1,
      },
    },
  };
};
