import {Theme} from '@material-ui/core';

export default (theme: Theme) => {
  return {
    MuiFormHelperText: {
      root: {
        marginTop: theme.spacing(0.5),
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        color: '#8D8E8D',
      },
    },
  };
};
