import React from 'react';
import {makeStyles, Theme, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import colors from '../../configs/colors';
const {black} = colors;
interface Props {
  labelTranslationKey: string;
  value: string;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  label: {
    marginRight: theme.spacing(2),
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '20px',
    color: black,
  },
  value: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: black,
  },
}));

const ContextPanelDataDisplay = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Typography className={classes.label}>
        {t(props.labelTranslationKey)}
      </Typography>
      <Typography className={classes.value}>{props.value}</Typography>
    </div>
  );
};

export default ContextPanelDataDisplay;
