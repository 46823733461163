import colors from '../../configs/colors';
import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    fontStyle: 'normal',
    fontFamily: 'Arial, sans-serif',
  },
  // Main elements
  table: {
    display: 'block',

    borderBottom: `1px solid ${colors.grey12}`,
    borderLeft: `1px solid ${colors.grey12}`,
    minWidth: '100%',
    //minHeight: '200%',

    background: colors.grey14,

    // Scroll bar
    overflowX: 'auto',

    borderSpacing: '0px',

    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
      height: theme.spacing(1),
    },

    '&::-webkit-scrollbar-track': {
      background: colors.grey12,
    },

    '&::-webkit-scrollbar-thumb': {
      background: colors.grey8,
    },
  },
  thead: {
    /* These styles are required for a scrollable body to align with the header properly */
    position: 'sticky',
    zIndex: 1,
    width: 'fit-content',
    minWidth: '100%',
    top: '0px',
  },
  tbody: {
    /* These styles are required for a scrollable table body */
    // height: `${theme.spacing(31.25)}px`,
    position: 'relative',
    zIndex: 0,
  },
  // Rows
  tr: {
    minHeight: theme.spacing(3.5),
    width: 'auto !important',
  },
  trBody: {
    background: colors.white,
    '&:nth-child(even)': {
      background: colors.grey15,
    },
  },
  trBodySubTable: {
    background: colors.grey15,
    '&:nth-of-type(even)': {
      background: colors.white,
    },
  },
  tdExpandCell: {
    height: '100%',
    margin: `0 -5px 0 -5px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  trIsSub: {
    borderLeft: `${theme.spacing(0.5)}px solid ${colors.blue5}`,
  },
  trSubBorders: {
    borderBottom: `${theme.spacing(0.5)}px solid ${colors.blue5}`,
  },
  trIsSelected: {
    background: colors.blue5,
  },
  trIsExpanded: {
    borderBottom: `${theme.spacing(0.1)}px solid ${colors.grey12}`,
  },
  trIsSubExpanded: {
    borderBottom: `${theme.spacing(0.05)}px solid ${colors.grey13}`,
  },
  // Header cells
  th: {
    display: 'flex !important',
    alignItems: 'center',

    position: 'relative',
    height: theme.spacing(3.5),
    padding: `0 ${theme.spacing(0.625)}px`,

    backgroundColor: colors.grey12,
    color: colors.grey4,
    fontWeight: 'bold',

    borderRight: `${theme.spacing(0.125)}px solid ${colors.white}`,
    overflow: 'hidden',
    boxShadow: '0',

    textAlign: 'left',

    '&[data-sticky-first-right-td="true"]': {
      borderLeft: `1px solid ${colors.grey5}`,
      borderRight: `1px solid ${colors.grey12}`,
    },
    '&[data-sticky-last-left-td="true"]': {
      boxShadow: `1px 0px 0px ${colors.grey5} !important`,
      borderRight: 'none',
    },
  },
  thRowSelect: {
    justifyContent: 'center',
  },
  thIsSortable: {
    '&:hover': {
      background: colors.blue5,
      cursor: 'pointer',
    },
  },
  // Body cells
  td: {
    padding: `0px ${theme.spacing(0.625)}px`,
    borderTop: `1px solid ${colors.grey12}`,
    borderRight: `1px solid ${colors.grey12}`,
    backgroundColor: 'inherit',
    position: 'relative',
    overflow: 'hidden',
    color: colors.grey2,
    '&[data-sticky-first-right-td="true"]': {
      borderLeft: `1px solid ${colors.grey5}`,
    },
    '&[data-sticky-last-left-td="true"]': {
      boxShadow: `1px 0px 0px ${colors.grey5} !important`,
    },
    '&:not([data-sticky-td="true"])': {
      flexGrow: 1,
    },
  },

  resizer: {
    display: 'inline-block',
    width: theme.spacing(1),
    background: 'transparent',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(50%)',
    zIndex: 1,
    /* prevents from scrolling while dragging on touch devices */
    touchAction: 'none',

    '&:hover': {
      width: theme.spacing(1.5),
      background: colors.grey10,
    },
  },
  // Keeping this one here to not forget its existence
  isResizing: {
    width: theme.spacing(1.5),
    background: colors.grey10,
  },
}));
