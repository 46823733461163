import * as React from 'react';
import colors from '../../configs/colors';
import {makeStyles, Theme} from '@material-ui/core';
import {getRowBackgroundColor} from './colors';
const {blue4} = colors;
interface Props {
  isOdd: boolean;
  isRowHovered: boolean;
  columnWidths: readonly number[];
  visibleColumns: readonly boolean[];
  children?: React.ReactNode;
  setIsRowHovered: (isHovered: boolean) => void;
  onClick?: () => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    minHeight: '32px',
    backgroundColor: props => getRowBackgroundColor(props.isOdd),
    borderBottom: '1px solid #F1F1F3',
    display: 'grid',
    gridTemplateRows: 'auto',
    position: 'relative',
    /* eslint-disable security/detect-object-injection */
    gridTemplateColumns: props =>
      props.columnWidths
        .map((w, i) => (props.visibleColumns[i] ? `${w}px` : '0px'))
        .join(' '),
    minWidth: props =>
      `${props.columnWidths
        .filter((_, i) => props.visibleColumns[i])
        .reduce((prev, current) => prev + current, 0)}px`,
    /* eslint-enable security/detect-object-injection */
    boxShadow: props =>
      props.isRowHovered ? '0px 3px 6px rgba(0, 0, 0, 0.15)' : 'none',
    zIndex: props => (props.isRowHovered ? 2 : 0),
    '&:active': {
      backgroundColor: blue4,
    },
  },
}));

const Row = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const classes = useStyles(props);

  const onMouseEnter = () => {
    props.setIsRowHovered(true);
  };
  const onMouseLeave = () => {
    props.setIsRowHovered(false);
  };

  return (
    <div
      className={classes.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
});

Row.displayName = 'Row';

export default Row;
