import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core';

interface Props {}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const CellBase: React.FC<Props> = props => {
  const classes = useStyles(props);
  return <div className={classes.root}>{props.children}</div>;
};

export default CellBase;
