import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ActionButton from '../Buttons/ActionButton';
import ValmetIcon from '../ValmetIcon';

export interface DialogButton {
  text: string;
  type: 'default' | 'ghost' | 'primary' | 'text';
  onClick: () => void;
}

interface Props {
  headerText: string;
  bodyText?: string;
  isOpen: boolean;
  width?: 'small' | 'medium';
  buttons: readonly DialogButton[];
  onClose: () => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
    lineHeight: '18px',
    fontSize: '18px',
  },
  dialogPaper: {
    width: props =>
      props.width === 'medium'
        ? '416px'
        : props.width === 'small'
        ? '296px'
        : undefined,
  },
}));

const DialogBase = (props: Props) => {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="dialog-title"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle id="dialog-title" disableTypography>
        <Typography variant="h2">{props.headerText}</Typography>
        <div className={classes.closeButton} onClick={props.onClose}>
          <ValmetIcon icon="close-circle" />
        </div>
      </DialogTitle>
      {!!props.bodyText && (
        <DialogContent>
          <Typography>{props.bodyText}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        {props.buttons.map(b => (
          <ActionButton
            key={b.text}
            type={b.type}
            text={b.text}
            onClick={b.onClick}
          />
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default DialogBase;
