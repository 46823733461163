import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Theme, makeStyles} from '@material-ui/core';
import colors from '../../configs/colors';
const {grey7} = colors;
interface Props {
  page: number;
  totalResults: number;
  pageSize: number;
  align: 'center' | 'left';
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  text: {
    color: grey7,
    lineHeight: '15px',
    textAlign: props => props.align,
  },
}));

const PageIndicator = (props: Props) => {
  const classes = useStyles(props);
  const {t} = useTranslation();
  let pageStart = props.page * props.pageSize + 1;
  if (props.totalResults === 0) {
    pageStart = 0;
  }

  const pageEnd = Math.min(
    props.page * props.pageSize + props.pageSize,
    props.totalResults,
  );

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        {t('pagination.pageIndicatorTemplate', {
          start: pageStart.toFixed(0),
          end: pageEnd.toFixed(0),
          results: props.totalResults.toFixed(0),
        })}
      </Typography>
    </div>
  );
};

export default PageIndicator;
