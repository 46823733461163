import {Theme} from '@material-ui/core';

export default (theme: Theme) => {
  return {
    MuiDivider: {
      root: {
        margin: [[theme.spacing(2), 0]],
      },
    },
  };
};
