import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles, Theme, Tooltip, Typography} from '@material-ui/core';
import colors from '../../configs/colors';
import TextButton from '../Buttons/TextButton';
import ValmetIcon from '../ValmetIcon';
const {white} = colors;
interface Props {
  titleTranslationKey: string;
  titleSuffix?: string;
  isPreviousPageLinkVisible: boolean;
  additionalContentAfterTitleText?: React.ReactNode;
  onCloseClick: () => void;
  onPreviousPageLinkClick: () => void;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    backgroundColor: white,
    minHeight: '120px',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(4.5),
  },
  buttons: {
    height: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-root': {
      height: '20px',
      paddingLeft: '0',
      paddingRight: '0',
      visibility: props =>
        props.isPreviousPageLinkVisible ? 'visible' : 'hidden',
    },
  },
  closeIcon: {
    cursor: 'pointer',
  },
  textAndAdditionalContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  textContainer: {
    minHeight: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4),
    flexGrow: 1,
  },
  text: {
    lineHeight: '32px',
    maxHeight: '64px', // 2 lines allowed
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
}));

const Title = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles(props);
  const titleRef = useRef<Element>(null!);
  const [isTextTooLong, setIsTextTooLong] = useState(false);
  const title = props.titleSuffix
    ? t(props.titleTranslationKey) + ' ' + props.titleSuffix
    : t(props.titleTranslationKey);
  useEffect(() => {
    const checkTitleFit = () => {
      const titleElement = titleRef.current;
      if (!titleElement) {
        return;
      }

      // The text is forced to multiple lines if there are no spaces
      // There will not be X overflow, it is always Y overflow
      // So we check the "scrollHeight" (aka the height the element should be so it doesn't have scrollbars)
      // against the "clientHeight", which is the rendered height.
      // If scroll height is greater, we have overflow.
      setIsTextTooLong(titleElement.scrollHeight > titleElement.clientHeight);
    };
    // Immediately run a check
    checkTitleFit();

    // On window resize, check the fit again, the panel width might change
    // eslint-disable-next-line scanjs-rules/call_addEventListener
    window.addEventListener('resize', checkTitleFit);
    return () => {
      // Clean up the listener
      window.removeEventListener('resize', checkTitleFit);
    };
  }, [title]);

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <TextButton
          onClick={props.onPreviousPageLinkClick}
          text="Previous page"
          icon="arrow-left"
        />
        <div className={classes.closeIcon} onClick={props.onCloseClick}>
          <ValmetIcon icon="close" size="medium" />
        </div>
      </div>
      <div className={classes.textAndAdditionalContentContainer}>
        <Tooltip
          title={title}
          placement="bottom"
          disableFocusListener={!isTextTooLong}
          disableHoverListener={!isTextTooLong}
          disableTouchListener={!isTextTooLong}
        >
          <div className={classes.textContainer}>
            <Typography
              className={classes.text}
              variant="h2"
              innerRef={titleRef}
            >
              {title}
            </Typography>
          </div>
        </Tooltip>
        {props.additionalContentAfterTitleText}
      </div>
    </div>
  );
};

export default Title;
