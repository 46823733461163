import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import colors from '../../configs/colors';
import ActionButton from '../Buttons/ActionButton';
const {white} = colors;
export interface BottomAction {
  translationKey: string;
  style: 'primary' | 'ghost';
  isDisabled?: boolean;
  onClick: () => void;
}

interface Props {
  actions: readonly BottomAction[];
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    height: '78px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
    backgroundColor: white,
    '& > button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const BottomActions = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      {props.actions.map(a => (
        <ActionButton
          key={a.translationKey}
          type={a.style}
          text={t(a.translationKey)}
          isDisabled={a.isDisabled}
          onClick={a.onClick}
        />
      ))}
    </div>
  );
};

export default BottomActions;
