import React from 'react';
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {templateTheme} from '../configs/theme';
import themeOverrides from '../configs/ThemeOverrides';

const baseTheme = createTheme(templateTheme);
const overrides = themeOverrides(baseTheme);
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const muiTheme = createTheme({...baseTheme, ...overrides} as any);

const withTheming = (WrappedComponent: any) => (props: any) =>
  (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <WrappedComponent {...props} />
    </MuiThemeProvider>
  );

export default withTheming;
