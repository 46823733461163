import React, {useState, useEffect, useCallback, useRef} from 'react';
import {makeStyles, Theme, Tooltip, Typography} from '@material-ui/core';
import HeaderCellBase from './HeaderCellBase';
import colors from '../../configs/colors';
import ValmetIcon from '../ValmetIcon';
import {useTranslation} from 'react-i18next';
import {Sort, TableSortDirection} from './types';
const {grey3, blue2, grey7} = colors;
interface Props {
  columnIndex: number;
  translationKey: string;
  isLastColumn: boolean;
  isSortable?: boolean;
  sortDirection?: TableSortDirection;
  initialSort?: Sort;
  onSortDirectionChange: (direction?: TableSortDirection) => void;
  onWidthChange: (width: number) => void;
}

const getNextSortDirection = (
  currentDirection: TableSortDirection | undefined,
  columnIndex: number,
  initialSort: Sort | undefined,
) => {
  // If column is default then we will just swap between directions, you can't remove the sort
  if (initialSort?.columnIndex === columnIndex) {
    return currentDirection === 'ascending' ? 'descending' : 'ascending';
  }

  // If column is not default column, flow is: No sort -> Ascending -> Descending -> No sort
  return currentDirection === undefined
    ? 'ascending'
    : currentDirection === 'ascending'
    ? 'descending'
    : undefined;
};

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    position: 'relative',
    paddingLeft: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    // In last column we have to hide overflow because the resize handle goes out of the container
    overflowX: props => (props.isLastColumn ? 'hidden' : 'initial'),
    cursor: props => (props.isSortable ? 'pointer' : 'normal'),
  },
  text: {
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '13px',
    lineHeight: '15px',
    color: grey3,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  sortButton: {
    paddingLeft: props =>
      props.sortDirection !== undefined
        ? theme.spacing(1.5)
        : theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    color: props => (props.sortDirection !== undefined ? blue2 : grey7),
    display: props => (props.isSortable ? 'initial' : 'none'),
    '& .icon-sort-column::before': {
      display: 'block',
      maxWidth: '20px',
    },
  },
}));

const HeaderCell = (props: Props) => {
  const classes = useStyles(props);
  const {t} = useTranslation();

  const [isResizing, setIsResizing] = useState(false);

  const rootElementRef = useRef<HTMLDivElement>(null!);
  const rootElementWidth = rootElementRef.current?.offsetWidth ?? 0;

  const handleCellClick = () => {
    if (
      !props.isSortable ||
      props.onSortDirectionChange === undefined ||
      isResizing
    ) {
      return;
    }

    const newDirection = getNextSortDirection(
      props.sortDirection,
      props.columnIndex,
      props.initialSort,
    );
    props.onSortDirectionChange(newDirection);
  };
  const onResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);
  const onResizeEnd = useCallback(() => {
    // isResizing is used as a guard to prevent cell click
    // After releasing the mouse, the mouseup event
    // comes first before the click event.
    // So if we setIsResizing(false) right away,
    // the click will register and treat it as a
    // sort change.
    // This prevents that for 100ms after the mouseup.
    // eslint-disable-next-line scanjs-rules/call_setTimeout
    setTimeout(() => {
      setIsResizing(false);
    }, 100);
  }, []);
  const nextSortDirection = getNextSortDirection(
    props.sortDirection,
    props.columnIndex,
    props.initialSort,
  );

  return (
    <HeaderCellBase>
      <Tooltip
        title={
          (nextSortDirection === 'ascending'
            ? t('table.tooltips.sortAscending')
            : nextSortDirection === 'descending'
            ? t('table.tooltips.sortDescending')
            : t('table.tooltips.resetSort')) ?? ''
        }
        placement="bottom"
        disableHoverListener={isResizing || !props.isSortable}
        disableFocusListener={!props.isSortable}
        disableTouchListener={!props.isSortable}
      >
        <div
          className={classes.root}
          onClick={handleCellClick}
          ref={rootElementRef}
        >
          <Typography className={classes.text} variant="body1">
            {t(props.translationKey)}
          </Typography>
          <div className={classes.sortButton}>
            <ValmetIcon
              size={props.sortDirection === undefined ? 'large' : 'default'}
              icon={
                props.sortDirection === undefined
                  ? 'sort-column'
                  : props.sortDirection === 'ascending'
                  ? 'arrow-up'
                  : 'arrow-down'
              }
            />
          </div>
          <ResizeHandle
            headerRootElementWidth={rootElementWidth}
            onWidthChange={props.onWidthChange}
            onResizeStart={onResizeStart}
            onResizeEnd={onResizeEnd}
          />
        </div>
      </Tooltip>
    </HeaderCellBase>
  );
};

export default HeaderCell;

const propagationStoppingHandler: React.ReactEventHandler<any> = ev => {
  ev.stopPropagation();
};

/*
The resizing calculation in the handle component can be a bit difficult to understand.
So here is an example of what happens:
User presses the mouse on the handle at say X position 632.
We then subtract it from the width of the header, say 179,
and get 179 - 632 = -453. This is stored as the start offset.
When we get a move event, we add the new X position to the offset to get the new width.
So say the user moved the mouse 10 pixels to the right and has X position 642.
So we get -453 + 642 = 189, which is the new updated width.
This might feel a bit odd but the alternative approach would be to store
both the starting width _and_ the starting X position, and then do 2 calculations
each time to get the updated width. (updated X - starting X + starting width)
Or rearranged: (starting width - starting X + updated X)
Since starting width - starting X will never change, we can just calculate it once
and store it.
*/

const useResizeHandleStyles = makeStyles<Theme, {}>(() => ({
  root: {
    width: '20px',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '-10px',
    boxSizing: 'border-box',
    cursor: 'col-resize',
    zIndex: 2,
  },
}));

const ResizeHandle = ({
  headerRootElementWidth,
  onResizeStart,
  onResizeEnd,
  onWidthChange,
}: {
  headerRootElementWidth: number;
  onResizeStart: () => void;
  onResizeEnd: () => void;
  onWidthChange: (width: number) => void;
}) => {
  const classes = useResizeHandleStyles({});
  const [{isResizing, startOffset}, setState] = useState({
    isResizing: false,
    startOffset: 0,
  });

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isResizing) {
        return;
      }

      const updatedWidth = startOffset + e.pageX;
      onWidthChange(updatedWidth);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isResizing, startOffset],
  );
  const onMouseUp = useCallback(
    () => {
      if (!isResizing) {
        return;
      }

      setState({isResizing: false, startOffset: 0});
      onResizeEnd();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isResizing],
  );

  useEffect(() => {
    // eslint-disable-next-line scanjs-rules/call_addEventListener
    document.addEventListener('mousemove', onMouseMove);
    // eslint-disable-next-line scanjs-rules/call_addEventListener
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMouseMove, onMouseUp]);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setState({
      isResizing: true,
      startOffset: headerRootElementWidth - e.pageX,
    });
    onResizeStart();
  };

  return (
    <div
      className={classes.root}
      onMouseDown={onMouseDown}
      onMouseOver={propagationStoppingHandler}
      title=""
    ></div>
  );
};
