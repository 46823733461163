import * as React from 'react';
import {
  FormHelperText,
  Theme,
  makeStyles,
  InputBase,
  Typography,
  InputBaseProps,
  InputBaseComponentProps,
} from '@material-ui/core';
import colors from '../../configs/colors';
import {useTranslation} from 'react-i18next';
import {InputWidth, Widths} from './InputWrapper';
import classNames from 'classnames';
const {grey3, grey6} = colors;
interface Props {
  InputBaseProps: Omit<
    InputBaseProps,
    'ref' | 'error' | 'inputRef' | 'required'
  >;
  inputProps?: InputBaseComponentProps;
  labelTranslationKey?: string;
  errorMessageTranslationKey?: string | null;
  helpTextTranslationKey?: string;
  isRequired?: boolean;
  /**
   * Adds an (optional) text after the label text.
   * Don't use with isRequired.
   */
  isOptional?: boolean;
  width?: InputWidth;
  placeholderTranslationKey?: string;
  /**
   * Renders a textarea if set to true.
   */
  multiLine?: boolean;
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows?: number;
  inputBgColor?: string;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    width: props => Widths[props.width ?? 'default'],
    maxWidth: '100%',
  },
  label: {
    display: 'flex',
  },
  labelText: {
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    color: grey3,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  requiredIndicator: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  optionalIndicator: {
    marginLeft: theme.spacing(0.5),
  },
  inputRoot: {
    width: props => Widths[props.width ?? 'default'],
    maxWidth: '100%',
    marginTop: props =>
      props.labelTranslationKey === undefined ? '0' : undefined,
  },
  inputInput: {
    // Calculate a fixed height based on number of rows set if the input is multi line
    // Both sides have border and padding totaling 9 pixels, so 18 + rows * line height is what we want
    height: props =>
      props.multiLine && props.rows !== undefined
        ? `${2 + 16 + props.rows * 14}px`
        : undefined,
    marginTop: 0,
  },
  helperText: {},
  errorText: {
    color: theme.palette.error.main,
  },
  adornedEnd: {
    '& .icon:last-child': {
      top: '8px',
      color: grey6,
    },
    '& .icon.unitMark:last-child': {
      fontSize: theme.spacing(1.625),
    },
  },
}));

const ValmetInputBase = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    if (props.multiLine && props.rows === undefined) {
      throw new Error('Must specify number of rows if multiLine set to true');
    }

    const {t} = useTranslation();
    const classes = useStyles(props);

    const {classes: propInputBaseClasses = {}, ...inputBaseProps} =
      props.InputBaseProps;
    const {
      root: propInputBaseRootClass,
      input: propInputBaseInputClass,
      ...propInputRestClasses
    } = propInputBaseClasses;
    const inputBaseClasses = {
      root: classNames(classes.inputRoot, propInputBaseRootClass),
      input: classNames(classes.inputInput, propInputBaseInputClass),
      adornedEnd: classes.adornedEnd,
      ...propInputRestClasses,
    };

    return (
      <>
        {props.labelTranslationKey !== undefined && (
          <label className={classes.label} htmlFor={props.InputBaseProps.id}>
            <Typography className={classes.labelText}>
              {t(props.labelTranslationKey)}
            </Typography>
            {props.isRequired && !props.isOptional && (
              <span className={classes.requiredIndicator}>*</span>
            )}
            {props.isOptional && !props.isRequired && (
              <span className={classes.optionalIndicator}>
                ({t('inputs.optional')})
              </span>
            )}
          </label>
        )}
        <InputBase
          style={{background: props.inputBgColor}}
          inputRef={ref}
          classes={inputBaseClasses}
          {...inputBaseProps}
          error={Boolean(props.errorMessageTranslationKey)}
          required={props.isRequired}
          inputProps={props.inputProps}
          placeholder={
            props.placeholderTranslationKey !== undefined
              ? t(props.placeholderTranslationKey)
              : undefined
          }
          multiline={props.multiLine}
          rows={props.rows}
        />
        {!props.errorMessageTranslationKey && props.helpTextTranslationKey && (
          <FormHelperText className={classes.helperText}>
            {t(props.helpTextTranslationKey)}
          </FormHelperText>
        )}
        {props.errorMessageTranslationKey && (
          <FormHelperText className={classes.errorText}>
            {t(props.errorMessageTranslationKey)}
          </FormHelperText>
        )}
      </>
    );
  },
);

export default ValmetInputBase;
