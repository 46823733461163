import * as React from 'react';
import classNames from 'classnames';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ValmetIcon from '../ValmetIcon';
import {IconNames} from '../ValmetIcon/IconNames';
import colors from '../../configs/colors';
const {white, blue2, grey14} = colors;
const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    backgroundColor: white,
    color: '#777777',
    border: `2px solid ${white}`,
    width: '40px',
    minWidth: '40px',
    height: '40px',
    '&:hover': {
      backgroundColor: white,
      color: '#4D4E50',
    },
    '&:focus': {
      border: `2px solid ${blue2}`,
      color: '#777777',
      backgroundColor: white,
    },
    '&:active': {
      backgroundColor: white,
      color: blue2,
    },
    '&:disabled': {
      backgroundColor: white,
      color: grey14,
    },
  },
}));

export interface Props {
  icon: IconNames;
  disabled?: boolean;
  onClick: () => void;
}

const IconButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {icon, ...rest} = props;
  const classes = useStyles(props);

  return (
    <Button
      className={classNames(classes.root)}
      size="large"
      ref={ref}
      {...rest}
    >
      <ValmetIcon icon={icon} size="medium" />
    </Button>
  );
});

export default IconButton;
