import * as React from 'react';
import classNames from 'classnames';
import {makeStyles, Theme} from '@material-ui/core/styles';
import colors from '../../configs/colors';
import ButtonBase, {Props as BaseProps} from './ButtonBase';
const {white, blue1, grey11, green2} = colors;
const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    color: white,
    '&:hover': {
      border: `2px solid ${green2}`,
      backgroundColor: green2,
    },
    '&:focus': {
      border: `2px solid ${blue1}`,
    },
    '&:active': {
      backgroundColor: '#04A904',
    },
    '&:disabled': {
      border: '2px solid #E0E1DD',
      backgroundColor: '#E0E1DD',
      color: grey11,
    },
  },
}));

export interface Props extends BaseProps {
  /**
   * CSS class overrides
   */
  classes?: {
    root?: string;
  };
}

const PrimaryButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {classes, iconColor, ...rest} = props;
    const internalClasses = useStyles(props);

    return (
      <ButtonBase
        iconColor={iconColor ?? (props.isDisabled ? grey11 : undefined)}
        classes={{
          root: classNames(internalClasses.root, classes?.root),
        }}
        ref={ref}
        {...rest}
      />
    );
  },
);

export default PrimaryButton;
