import {Theme} from '@material-ui/core';
import colors from '../colors';

export default (theme: Theme) => {
  return {
    MuiChip: {
      root: {
        height: '20px',
        borderRadius: '2px',
        backgroundColor: colors.grey10,
        maxWidth: '100%',
      },
      deletable: {},
      label: {
        lineHeight: '16px',
        fontSize: '13px',
        color: colors.grey3,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(1),
      },
      deleteIcon: {
        margin: theme.spacing(0, 1, 0, 1.25),
        height: '13px',
        width: '13px',
        lineHeight: '13px',
      },
    },
  };
};
