import {Theme} from '@material-ui/core';
import colors from '../colors';

export default (theme: Theme) => {
  return {
    MuiCheckbox: {
      root: {
        backgroundColor: `${colors.white} !important`,
        margin: theme.spacing(0, 1, 0, 1),
        padding: 0,
      },
    },
  };
};
