import {Theme} from '@material-ui/core';
import colors from '../colors';

export default (theme: Theme) => {
  return {
    MuiRadio: {
      root: {
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingRight: theme.spacing(1),
        color: colors.grey5,
        transform: 'scale(1.1)',
        '&:hover': {
          backgroundColor: 'initial !important',
        },
        '&.Mui-checked': {
          color: `${colors.blue2} !important`,
        },
        '&.Mui-disabled': {
          color: `${colors.grey10} !important`,
        },
        '& .MuiIconButton-label': {
          position: 'relative',
        },
        '&.Mui-focusVisible .MuiIconButton-label:after': {
          content: "''",
          display: 'block',
          position: 'absolute',
          top: '-1px',
          bottom: '-1px',
          left: '-1.5px',
          right: '-1px',
          border: `2px solid ${colors.blue2}`,
          borderRadius: '50%',
        },
      },
    },
  };
};
