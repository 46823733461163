import {Theme} from '@material-ui/core';
import colors from '../colors';
const {white, blue2} = colors;
export default (theme: Theme) => {
  return {
    MuiAutocomplete: {
      hasPopupIcon: {
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: '0 !important',
        },
        '& .MuiAutocomplete-inputRoot > .MuiInputBase-input': {
          paddingRight: '36px', // 12px regular padding + 12px icon width + 12px padding from icon
        },
        '& .MuiAutocomplete-inputRoot > .MuiAutocomplete-endAdornment': {
          position: 'absolute',
          top: '8px',
          right: '12px',
        },
        '& .MuiAutocomplete-inputRoot > .MuiAutocomplete-endAdornment .icon': {
          fontSize: '12px',
          lineHeight: '12px',
        },
      },
      popupIndicator: {
        padding: '0',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      clearIndicator: {
        display: 'none',
      },
      paper: {
        margin: '0',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      },
      listbox: {
        padding: '0',
      },
      option: {
        backgroundColor: white,
        borderBottom: '1px solid #F2F2F2',
        padding: theme.spacing(1),
        alignItems: 'flex-start',
        minHeight: 'auto !important',
        '& > .icon': {
          fontSize: '12px',
          visibility: 'hidden',
        },
        '& > p': {
          lineHeight: '15px',
          marginLeft: '5px',
        },
        '&:last-of-type': {
          borderBottom: 'none',
        },
        "&[data-focus='true']": {
          // Hover/focus is implemented like this :\
          backgroundColor: '#E6F1F6',
        },
        "&[aria-selected='true']": {
          // And select like this :\
          backgroundColor: white,
        },
        "&[aria-selected='true'] > .icon": {
          visibility: 'visible',
        },
        "&[aria-selected='true'] > p": {
          color: blue2,
        },
      },
    },
  };
};
