import {Theme} from '@material-ui/core';

export default (_: Theme) => {
  return {
    MuiButton: {
      root: {
        height: '36px',
        minWidth: '64px',
        boxSizing: 'border-box',
        borderRadius: '2px',
      },
      text: {
        padding: '0 8px 0 8px',
        justifyContent: 'center',
      },
      sizeLarge: {
        height: '40px',
      },
      sizeSmall: {
        height: '24px',
      },
      contained: {
        boxShadow: 'none',
        '&:active, &:hover': {
          boxShadow: 'none',
        },
      },
    },
  };
};
