import * as React from 'react';
import DefaultButton from './DefaultButton';
import GhostButton from './GhostButton';
import PrimaryButton from './PrimaryButton';
import TextButton from './TextButton';
import {Props as BaseProps} from './ButtonBase';

export interface Props extends BaseProps {
  /**
   * Defines which button type is used (default: "default")
   */
  type?: 'default' | 'ghost' | 'primary' | 'text';
}

const ActionButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {type, ...rest} = props;

    return (
      <>
        {type === 'primary' && <PrimaryButton ref={ref} {...rest} />}
        {type === 'ghost' && <GhostButton ref={ref} {...rest} />}
        {type === 'text' && <TextButton ref={ref} {...rest} />}
        {type !== 'primary' && type !== 'ghost' && type !== 'text' && (
          <DefaultButton ref={ref} {...rest} />
        )}
      </>
    );
  },
);

export default ActionButton;
