import * as React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, {TableCellProps} from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Box, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import colors from '../../configs/colors';
import ValmetIcon from '../ValmetIcon';
import classNames from 'classnames';
const {grey11, grey3} = colors;

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  headerText: {
    fontWeight: 700,
    color: `${grey3}`,
    lineHeight: '15px',
  },
});

const StyledTableHead = withStyles(() => ({
  root: {
    border: `1px solid ${grey11}`,
  },
}))(TableHead);
const StyledTableHeadCell = withStyles(() => ({
  root: {
    borderRight: `1px solid ${grey11}`,
    '& > .MuiTypography-root': {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}))(TableCell);
const StyledLastColumnTableHeadCell = withStyles(() => ({
  root: {
    '& > .MuiTypography-root': {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}))(TableCell);

export interface ValmetBasicTableColumn {
  /**
   * Translation key used to get the column header text
   */
  translationKey: string;
  /**
   * Width of the column, will be applied inline to the header cell as width CSS prop (prefer relative values like 50%)
   */
  width: string;
}

export type Props = {
  /**
   * Columns to show in the table
   */
  columns: readonly ValmetBasicTableColumn[];
};

export const ValmetBasicTable: React.FC<Props> = ({children, columns}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <StyledTableHead>
          <TableRow>
            {columns.map(({translationKey, width}, i) => {
              // Last table cell does not have a right border
              // So we don't use the styled one if this is the last one
              const isLast = i === columns.length - 1;
              const Comp = isLast
                ? StyledLastColumnTableHeadCell
                : StyledTableHeadCell;
              return (
                <Comp
                  key={translationKey}
                  style={{
                    width,
                  }}
                >
                  <Typography className={classes.headerText}>
                    {t(translationKey)}
                  </Typography>
                </Comp>
              );
            })}
          </TableRow>
        </StyledTableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

const useRemoveButtonStyles = makeStyles({
  root: {
    marginLeft: '8px',
  },
});
interface RemoveButtonProps {
  onClick: () => void;
}
export const ValmetBasicTableRemoveButton = ({onClick}: RemoveButtonProps) => {
  const classes = useRemoveButtonStyles();
  const {t} = useTranslation();
  return (
    <ValmetIcon
      className={classes.root}
      icon="delete"
      onClick={onClick}
      tooltip={t('tooltips.delete')}
    />
  );
};

const useBasicTableCellStyles = makeStyles({
  root: {
    borderColor: colors.grey15,
    padding: '4px 8px',
    '& > .MuiTypography-root': {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

/**
 * Cell used within a TableRow inside ValmetBasicTable
 */
export const ValmetBasicTableCell = (props: Omit<TableCellProps, 'ref'>) => {
  const classes = useBasicTableCellStyles();

  // We can just apply other props but we need to merge the classes objects
  // If a root class is given as a prop, it should be used along with the one defined here
  const {classes: propClasses, ...rest} = props;

  return (
    <TableCell
      {...rest}
      classes={{
        ...propClasses,
        root: classNames(classes.root, propClasses?.root),
      }}
    />
  );
};

/**
 * Same as ValmetBasicTableCell, except it shows a remove button on the right edge along with your content
 */
export const ValmetBasicTableCellWithRemoveButton: React.FC<
  Omit<TableCellProps, 'ref'> & {
    onRemoveClick: () => void;
  }
> = props => {
  const {children, onRemoveClick, ...rest} = props;
  return (
    <ValmetBasicTableCell {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {children}
        <ValmetBasicTableRemoveButton onClick={onRemoveClick} />
      </Box>
    </ValmetBasicTableCell>
  );
};
