import React from 'react';
import PageSizeSelector from './PageSizeSelector';
import PageIndicator from './PageIndicator';
import {Pagination as PageNavigation} from '@material-ui/lab';
import {Box, makeStyles} from '@material-ui/core';
import TextButton from '../Buttons/TextButton';
import {useTranslation} from 'react-i18next';
import colors from '../../configs/colors';

export enum PaginationMode {
  Desktop,
  Mobile,
}

export interface Props {
  currentPage: number;
  /**
   * Desktop is default if undefined
   */
  mode?: PaginationMode;
  pageSize: number;
  pageSizeOptions: number[];
  rowAmount: number;
  onChangePageSize: (pageSize: number) => void;
  onChangeCurrentPage: (currentPage: number) => void;
}

function DesktopPagination({
  currentPage,
  pageSize,
  pageSizeOptions,
  rowAmount,
  onChangePageSize,
  onChangeCurrentPage,
}: Props) {
  const pageCount = Math.ceil(rowAmount / pageSize);
  return (
    <Box display="flex" alignItems="center" width="100%" mt={1.25}>
      <PageSizeSelector
        pageSize={pageSize}
        pageSizes={pageSizeOptions}
        onChange={onChangePageSize}
      />
      <PageIndicator
        align="left"
        page={currentPage}
        totalResults={rowAmount}
        pageSize={pageSize}
      />
      <PageNavigation
        color="primary"
        count={pageCount}
        shape="rounded"
        page={currentPage + 1}
        onChange={(_, newPage) => {
          onChangeCurrentPage(newPage - 1);
        }}
      />
    </Box>
  );
}

interface MobileButtonProps {
  isDisabled: boolean;
  onClick: () => void;
}

const useMobileButtonStyles = makeStyles(() => ({
  root: {
    background: colors.grey10,
    color: colors.grey4,
    fontWeight: 'bold',
    border: `1px solid ${colors.grey10}`,
    '&:hover': {
      backgroundColor: colors.grey11,
    },
    '&:disabled': {
      background: colors.grey10,
      color: colors.white,
    },
  },
}));

function MobilePreviousButton(props: MobileButtonProps) {
  const classes = useMobileButtonStyles();
  const {t} = useTranslation();
  return (
    <TextButton
      className={classes.root}
      isDisabled={props.isDisabled}
      onClick={props.onClick}
      icon="arrow-left"
      iconColor={props.isDisabled ? colors.white : undefined}
      text={t('pagination.previous')}
    />
  );
}

function MobileNextButton(props: MobileButtonProps) {
  const classes = useMobileButtonStyles();
  const {t} = useTranslation();
  return (
    <TextButton
      className={classes.root}
      isDisabled={props.isDisabled}
      onClick={props.onClick}
      icon="arrow-right"
      iconPosition="right"
      iconColor={props.isDisabled ? colors.white : undefined}
      text={t('pagination.next')}
    />
  );
}

function MobilePagination({
  currentPage,
  rowAmount,
  pageSize,
  onChangeCurrentPage,
}: Props) {
  const pageCount = Math.ceil(rowAmount / pageSize);

  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === pageCount - 1;

  const onPreviousClick = () => {
    if (currentPage === 0) {
      return;
    }

    onChangeCurrentPage(currentPage - 1);
  };
  const onNextClick = () => {
    const maxPage = pageCount;
    if (currentPage === maxPage) {
      return;
    }

    onChangeCurrentPage(currentPage + 1);
  };
  return (
    <Box display="flex" alignItems="center" width="100%" mt={1.25}>
      <MobilePreviousButton
        isDisabled={isFirstPage}
        onClick={onPreviousClick}
      />
      <PageIndicator
        align="center"
        page={currentPage}
        totalResults={rowAmount}
        pageSize={pageSize}
      />
      <MobileNextButton isDisabled={isLastPage} onClick={onNextClick} />
    </Box>
  );
}

const Pagination = (props: Props) => {
  if (props.mode === PaginationMode.Mobile) {
    return <MobilePagination {...props} />;
  }

  return <DesktopPagination {...props} />;
};

export default Pagination;
