import * as React from 'react';
import {
  Box,
  ClickAwayListener,
  makeStyles,
  Popper,
  Theme,
} from '@material-ui/core';
import MultiSelectMenu, {Option} from '../Menus/MultiSelectMenu';
import colors from '../../configs/colors';
import ButtonBase from '../Buttons/ButtonBase';
import {useFilter} from './context';
import {enUS} from 'date-fns/locale';
import {
  format,
  isSameDay,
  isValid,
  startOfDay,
  endOfDay,
  isBefore,
} from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import IconButton from '../Buttons/IconButton';
import SingleSelectMenu from '../Menus/SingleSelectMenu';

export enum TFilterTypeEnum {
  Boolean = 0,
  DateTime = 1,
  DateTimeRange = 2,
  InSingleValue = 3,
  InArrayValues = 4,
}

export interface IFilterBarItem {
  id: string;
  title: string;
  type: TFilterTypeEnum;
  options?: Option[];
  /**
   * If false, hides the Select All
   */
  showSelectAllButtonForOptionsMenu?: boolean;
}
export interface Props {
  scheme: IFilterBarItem[];
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    backgroundColor: colors.grey15,
    border: `1px solid ${colors.grey12}`,
    borderTop: 'none',
    '&>*': {
      borderRight: `1px solid ${colors.grey12}`,
    },
  },
}));
export function FilterBar(props: Props) {
  const {scheme} = props;
  const classes = useStyles(props);
  return (
    <Box className={classes.root}>
      {scheme.map(item => {
        switch (item.type) {
          case TFilterTypeEnum.InArrayValues:
            return <TypeInArraValues key={item.id} {...item} />;
          case TFilterTypeEnum.DateTimeRange:
            return <TypeDateTimeRange key={item.id} {...item} />;
          case TFilterTypeEnum.InSingleValue:
            return <TypeInSingleValue key={item.id} {...item} />;
          default:
            return <></>;
        }
      })}
    </Box>
  );
}
function useToggle() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return {handleClose, anchorEl, handleOpen};
}

function useTypeFilter(id: string) {
  const {handleClose, handleOpen, anchorEl} = useToggle();
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);
  const {selected, up} = useFilter();
  React.useEffect(() => {
    if (anchorEl) {
      setSelectedOptions(
        selected
          .filter(chip => chip.filterId === id)
          .map(({text, value}) => {
            return {text, value};
          }),
      );
    }
  }, [selected, anchorEl, id]);

  const memoizedHandleOnSelectedOptionsChange = React.useCallback(
    (nextFilteredOptions: Option[], type: TFilterTypeEnum) => {
      const selectedByCurrentId = selected.filter(chip => chip.filterId === id);
      const outdated = selectedByCurrentId.filter(
        chip =>
          !nextFilteredOptions.some(option => option.value === chip.value),
      );

      const data = selected.filter(
        option =>
          !outdated.some(
            chip => chip.value === option.value && option.filterId === id,
          ),
      );
      const nextChips = [
        ...data,
        ...nextFilteredOptions
          .map(option => ({...option, filterId: id, type}))
          .filter(
            chip =>
              !data.some(
                dataChip =>
                  dataChip.filterId === chip.filterId &&
                  dataChip.value === chip.value,
              ),
          ),
      ];
      up(nextChips);
    },
    [selected, id, up],
  );
  return {
    handleOpen,
    handleClose,
    anchorEl,
    onSelectedOptionsChange: memoizedHandleOnSelectedOptionsChange,
    selectedOptions,
  };
}

function TypeInSingleValue({title, options, id}: IFilterBarItem) {
  const {
    handleOpen,
    handleClose,
    anchorEl,
    onSelectedOptionsChange,
    selectedOptions,
  } = useTypeFilter(id);

  const selectedOption = selectedOptions.length > 0 ? selectedOptions[0] : null;

  return (
    <>
      <ArrowButton onClick={handleOpen} title={title} />
      <SingleSelectMenu
        anchorEl={anchorEl}
        selectedOption={selectedOption}
        options={options || []}
        onOptionSelected={option => {
          onSelectedOptionsChange([option], TFilterTypeEnum.InSingleValue);
        }}
        onClose={handleClose}
      />
    </>
  );
}

function TypeInArraValues({
  title,
  options,
  id,
  showSelectAllButtonForOptionsMenu,
}: IFilterBarItem) {
  const {
    handleOpen,
    handleClose,
    anchorEl,
    onSelectedOptionsChange,
    selectedOptions,
  } = useTypeFilter(id);
  return (
    <>
      <ArrowButton onClick={handleOpen} title={title} />
      <MultiSelectMenu
        anchorEl={anchorEl}
        placement="bottom"
        onClose={handleClose}
        onSelectedOptionsChange={selectedOptions =>
          onSelectedOptionsChange(
            selectedOptions,
            TFilterTypeEnum.InArrayValues,
          )
        }
        options={options || []}
        selectedOptions={selectedOptions}
        showFilter
        offset="75, 1"
        selectedOptionsFirst
        showSelectAllButton={showSelectAllButtonForOptionsMenu}
      />
    </>
  );
}
function ArrowButton({
  onClick,
  title,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
}) {
  return (
    <ButtonBase
      icon="arrow-down"
      onClick={onClick}
      text={title}
      iconPosition="right"
    />
  );
}
//DatePickerRangeMenu

function TypeDateTimeRange({title, id}: IFilterBarItem) {
  const {handleOpen, ...rest} = useTypeFilter(id);
  return (
    <>
      <ArrowButton onClick={handleOpen} title={title} />
      <DatePickerRangeMenu {...rest} />
    </>
  );
}
const useStylesDate = makeStyles<Theme>(theme => ({
  root: {
    '&:focus': {
      outline: 'none',
    },
    padding: `${theme.spacing(1)}px`,
    width: `${theme.spacing(33)}px`,
  },
  list: {
    maxHeight: () => `${8 * 32}px`,
    overflowY: 'auto',
    '& .react-datepicker-popper': {
      width: '330px',
    },
    '& .react-datepicker__triangle': {
      left: '60px !important',
    },
    '& .react-datepicker__input-container input': {
      color: '#4C4D4F',
      width: '100%',
      border: '1px solid #8B8D8E',
      height: '32px',
      margin: 0,
      display: 'block',
      padding: '8px 12px',
      fontSize: '13px',
      minWidth: 0,
      background: 'none',
      boxSizing: 'border-box',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '14px',
      borderRadius: '2px',
      animationName: 'mui-auto-fill-cancel',
      animationDuration: '10ms',
      tapHighlightColor: 'transparent',
      '&:focus': {
        border: '2px solid #018ABA !important',
        outline: 0,
      },
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5, 1),
    borderTop: `1px solid ${colors.grey12}`,
    '& > *:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  filter: {
    padding: theme.spacing(0, 0.5, 0.5, 0.5),
    '& > .MuiInputBase-adornedStart > .icon': {
      fontSize: '13px',
    },
    '& > .MuiInputBase-adornedStart > input': {
      padding: '6px 11px 6px 30px',
    },
    '& > .MuiInputBase-adornedStart > input:focus': {
      padding: '6px 11px 6px 30px !important',
    },
  },
  popper: {
    filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))',
    backgroundColor: colors.white,
    zIndex: 1301, // 1 higher than the context panel
  },
}));
export interface IDateInputProps {
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  onChange?: (val: Date | null) => void;
  handleClose: () => void;
  anchorEl: Element | null;
  onSelectedOptionsChange: (
    selectedOptions: Option[],
    type: TFilterTypeEnum,
  ) => void;
  selectedOptions: Option[];
}
function DatePickerRangeMenu(props: IDateInputProps) {
  const {handleClose, anchorEl, onSelectedOptionsChange, selectedOptions} =
    props;

  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  React.useEffect(() => {
    const start = startDate ? `${startDate}` : '';
    const end = endDate ? `${endDate}` : '';
    const doFormat = (date: Date) => format(date, 'dd.MM.y HH:mm');
    if (start && !end) {
      onSelectedOptionsChange(
        [
          {
            text: `${doFormat(new Date(start))} - *`,
            value: JSON.stringify({start, end}),
          },
        ],
        TFilterTypeEnum.DateTimeRange,
      );
    } else if (!start && end) {
      onSelectedOptionsChange(
        [
          {
            text: `* - ${doFormat(new Date(end))}`,
            value: JSON.stringify({start, end}),
          },
        ],
        TFilterTypeEnum.DateTimeRange,
      );
    } else if (start && end) {
      onSelectedOptionsChange(
        [
          {
            text: `${doFormat(new Date(start))} - ${doFormat(new Date(end))}`,
            value: JSON.stringify({start, end}),
          },
        ],
        TFilterTypeEnum.DateTimeRange,
      );
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);
  React.useEffect(() => {
    if (!selectedOptions.length) {
      setStartDate(null);
      setEndDate(null);
    } else {
      const [option] = selectedOptions;
      const {start, end} = JSON.parse(option.value) as {
        start: string;
        end: string;
      };
      const startD = new Date(start);
      const endD = new Date(end);
      if (isValid(startD)) {
        setStartDate(startD);
      }
      if (isValid(endD)) {
        setEndDate(endD);
      }
    }
    // eslint-disable-next-line
  }, [selectedOptions.length]);

  const classes = useStylesDate();
  const {t} = useTranslation();
  const refPickerStart = React.useRef<DatePicker | null>();
  const refPickerEnd = React.useRef<DatePicker | null>();
  const {startMinMaxTime, endMinMaxTime} = (() => {
    // If both datetimes are selected, then we only
    // limit the min and max time when they are on the same day.
    // If only one is defined, we can assume the other is on
    // the same day as that is the default what is shown.
    // The goal is to prevent selections where start > end.
    if (startDate && endDate) {
      if (isSameDay(startDate, endDate)) {
        return {
          startMinMaxTime: {
            minTime: startOfDay(endDate),
            maxTime: endDate,
          },
          endMinMaxTime: {
            minTime: startDate,
            maxTime: endOfDay(startDate),
          },
        };
      }
    } else if (startDate) {
      return {
        startMinMaxTime: {},
        endMinMaxTime: {
          minTime: startDate,
          maxTime: endOfDay(startDate),
        },
      };
    } else if (endDate) {
      return {
        startMinMaxTime: {
          minTime: startOfDay(endDate),
          maxTime: endDate,
        },
        endMinMaxTime: {},
      };
    }

    return {startMinMaxTime: {}, endMinMaxTime: {}};
  })();
  const [, setIsOpened] = React.useState(false);
  return (
    <ClickAwayListener
      onClickAway={handleClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Popper
        id="multi-select-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        className={classes.popper}
        placement={'bottom'}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0',
          },
        }}
      >
        <div className={classes.root} tabIndex={-1}>
          <div className={classes.list}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <DatePicker
                locale={{
                  ...enUS,
                  options: {
                    weekStartsOn: 1,
                  },
                }}
                onInputClick={() => {
                  refPickerStart.current?.setOpen(true);
                  setIsOpened(true);
                }}
                ref={(c: any) => {
                  refPickerStart.current = c;
                }}
                selected={startDate}
                onCalendarClose={() => {
                  // eslint-disable-next-line scanjs-rules/call_setTimeout
                  setTimeout(() => {
                    setIsOpened(false);
                  }, 500);
                }}
                onChange={date => {
                  // We don't have a similar check here
                  // like for end datetime as it is
                  // not possible for a user to choose
                  // a datetime after the end.
                  // If a user clicks on a date and it
                  // the same as the end date, the
                  // time will default at midnight,
                  // which is the smallest possible value.
                  setStartDate(date as Date);
                }}
                selectsStart
                maxDate={endDate}
                {...startMinMaxTime}
                startDate={startDate}
                placeholderText={t('filter.placeholderStart')}
                showTimeSelect
                dateFormat="dd.MM.y HH:mm"
                timeFormat="HH:mm"
              />
              <IconButton
                icon="production-summary"
                onClick={() => {
                  setIsOpened(prev => {
                    refPickerStart.current?.setOpen(!prev);
                    return !prev;
                  });
                }}
              />
            </Box>
            <Box
              mt={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <DatePicker
                locale={{
                  ...enUS,
                  options: {
                    weekStartsOn: 1,
                  },
                }}
                ref={(c: any) => {
                  refPickerEnd.current = c;
                }}
                onInputClick={() => {
                  refPickerEnd.current?.setOpen(true);
                  setIsOpened(true);
                }}
                onCalendarClose={() => {
                  // eslint-disable-next-line scanjs-rules/call_setTimeout
                  setTimeout(() => {
                    setIsOpened(false);
                  }, 500);
                }}
                selected={endDate}
                onChange={date => {
                  // The reason this check exists is that
                  // if a user clicks on a date,
                  // that day at midnight is selected.
                  // So if the user clicks on the start date,
                  // it will most likely be before the start time.
                  // In that case we adjust the end time to the start time.
                  if (date && startDate && isBefore(date, startDate)) {
                    setEndDate(startDate);
                  } else {
                    setEndDate(date as Date);
                  }
                }}
                selectsEnd
                placeholderText={t('filter.placeholderEnd')}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                {...endMinMaxTime}
                showTimeSelect
                dateFormat="dd.MM.y HH:mm"
                timeFormat="HH:mm"
              />
              <IconButton
                icon="production-summary"
                onClick={() => {
                  setIsOpened(prev => {
                    refPickerEnd.current?.setOpen(!prev);
                    return !prev;
                  });
                }}
              />
            </Box>
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
}
