import {CircularProgress} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface ILoadingScreenOwnProps {
  text?: string;
  showText?: boolean;
}

export type ComponentProps = ILoadingScreenOwnProps;

const LoadingScreen = (props: ComponentProps) => {
  const {text, showText} = props;

  const {t} = useTranslation();
  return (
    <Card style={{width: '100%'}}>
      <CardContent>
        <div style={{textAlign: 'center'}}>
          <CircularProgress />

          {!!text && showText && (
            <>
              {' '}
              <br />
              <br />
              <p>{t(text)}</p>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default LoadingScreen;
