import {makeStyles, Theme} from '@material-ui/core';
import * as React from 'react';
import EquipmentLevelIndicator, {EquipmentLevelIndicatorProps} from '.';

const useStyles = makeStyles<Theme, {}>(() => ({
  wrapper: {
    display: 'flex',
    //alignItems: 'space-around',
    gap: '3px',
    flexWrap: 'wrap',
  },
}));

const EquipmentLevelIndicatorWrapper = ({
  equipments,
}: {
  equipments: EquipmentLevelIndicatorProps[];
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {equipments.map((equ, index) => (
        <EquipmentLevelIndicator key={index} equipment={equ} />
      ))}
    </div>
  );
};

export default EquipmentLevelIndicatorWrapper;
