import React, {useMemo, useRef, useState} from 'react';
import {
  makeStyles,
  Theme,
  InputBase,
  Popper,
  ClickAwayListener,
  PopperPlacementType,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {includes, indexOf, remove, uniqWith} from 'ramda';
import colors from '../../configs/colors';
import TextButton from '../Buttons/TextButton';
import {VariableSizeList as List} from 'react-window';
import ValmetIcon from '../ValmetIcon';
import ValmetCheckbox from '../Inputs/ValmetCheckbox';
const {grey15, grey12, grey10, white} = colors;
export interface Option {
  text: string;
  value: string;
}

interface Props {
  anchorEl: Element | null;
  placement: PopperPlacementType;
  options: readonly Option[];
  selectedOptions: readonly Option[];
  selectedOptionsFirst?: boolean;
  showFilter?: boolean;
  widthPixels?: number;
  /**
   * Offset the menu from the standard position.
   * Check docs for the format: https://popper.js.org/docs/v1/#modifiersoffset
   */
  offset?: string;
  containerEl?: Element | null;
  /**
   * If false, hides the Select All button.
   * By default the button is shown.
   */
  showSelectAllButton?: boolean;
  onSelectedOptionsChange: (selectedOptions: Option[]) => void;
  onClose: () => void;
}

const useStyles = makeStyles<Theme, {widthPixels?: number}>(theme => ({
  root: {
    width: props =>
      props.widthPixels !== undefined ? `${props.widthPixels}px` : undefined,
    '&:focus': {
      outline: 'none',
    },
  },
  list: {
    maxHeight: () => `${8 * 32}px`,
    overflowY: 'auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5, 1),
    borderTop: `1px solid ${grey12}`,
    '& > *:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  filter: {
    padding: theme.spacing(0, 0.5, 0.5, 0.5),
    '& > .MuiInputBase-adornedStart > .icon': {
      fontSize: '13px',
    },
    '& > .MuiInputBase-adornedStart > input': {
      padding: '6px 11px 6px 30px',
    },
    '& > .MuiInputBase-adornedStart > input:focus': {
      padding: '6px 11px 6px 30px !important',
    },
  },
  popper: {
    filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))',
    backgroundColor: white,
    zIndex: 1301, // 1 higher than the context panel
  },
}));

const MultiSelectMenu = (props: Props) => {
  const classes = useStyles({widthPixels: props.widthPixels});
  const {t} = useTranslation();
  const [filterValue, setFilterValue] = useState('');
  const [keyboardFocusedOption, setKeyboardFocusedOption] =
    useState<number | null>(null);
  const filteredOptions = useMemo(() => {
    if (!filterValue) {
      return props.options;
    }

    const filterLowerCase = filterValue.toLowerCase();
    return props.options.filter(
      o => o.text.toLowerCase().indexOf(filterLowerCase) >= 0,
    );
  }, [props.options, filterValue]);
  const sortedOptions = useMemo(() => {
    if (
      props.selectedOptionsFirst !== true ||
      props.selectedOptions.length === 0
    ) {
      return filteredOptions;
    }

    const selectedOptionSet = new Set<Option>(props.selectedOptions);
    return [
      ...filteredOptions.filter(o => selectedOptionSet.has(o)),
      ...filteredOptions.filter(o => !selectedOptionSet.has(o)),
    ];
  }, [props.selectedOptions, filteredOptions, props.selectedOptionsFirst]);
  const virtualListContainerRef = useRef<List>(null!);
  const popperModifiers = useMemo(() => {
    return {
      offset: {
        enabled: props.offset !== undefined,
        offset: props.offset ?? '0',
      },
    };
  }, [props.offset]);

  const onSelectAllClick = () => {
    const optionsToSelect = filteredOptions;

    const newSelectedOptions = uniqWith<Option, Option>(
      (a, b) => a.value === b.value,
      optionsToSelect.concat(props.selectedOptions),
    );
    props.onSelectedOptionsChange(newSelectedOptions);
  };
  const onClearClick = () => {
    props.onSelectedOptionsChange([]);
  };
  const onClose = () => {
    setFilterValue('');
    setKeyboardFocusedOption(null);
    props.onClose();
  };
  const onFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(ev.target.value);
    setKeyboardFocusedOption(null);
  };
  const onOptionSelectedChange = (index: number, selected: boolean) => {
    /* eslint-disable security/detect-object-injection */
    props.onSelectedOptionsChange(
      selected
        ? props.selectedOptions.concat([sortedOptions[index]])
        : remove(
            indexOf(sortedOptions[index], props.selectedOptions),
            1,
            props.selectedOptions,
          ),
    );
    /* eslint-enable security/detect-object-injection */
  };
  const onKeyDown = (ev: React.KeyboardEvent<unknown>) => {
    let newFocusedIndex: number | undefined;
    if (ev.key === 'ArrowDown') {
      // Move selection down
      newFocusedIndex = (keyboardFocusedOption ?? -1) + 1;
      if (newFocusedIndex >= sortedOptions.length) {
        newFocusedIndex = 0;
      }
    } else if (ev.key === 'ArrowUp') {
      // Move selection up
      newFocusedIndex = (keyboardFocusedOption ?? 0) - 1;
      if (newFocusedIndex < 0) {
        newFocusedIndex = sortedOptions.length - 1;
      }
    } else if (ev.key === 'Enter' && keyboardFocusedOption !== null) {
      /* eslint-disable security/detect-object-injection */
      onOptionSelectedChange(
        keyboardFocusedOption,
        !includes(sortedOptions[keyboardFocusedOption], props.selectedOptions),
      );
      /* eslint-enable security/detect-object-injection */
    }

    if (newFocusedIndex !== undefined) {
      setKeyboardFocusedOption(newFocusedIndex);
      virtualListContainerRef.current.scrollToItem(newFocusedIndex, 'auto');
    }
  };
  const onFilterKeyDown = (ev: React.KeyboardEvent<unknown>) => {
    // Prevent arrow up and arrow down from moving the cursor in the filter field
    if (ev.key === 'ArrowDown' || ev.key === 'ArrowUp') {
      ev.preventDefault();
    }
  };

  return (
    <ClickAwayListener
      onClickAway={onClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Popper
        id="multi-select-menu"
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        placement={props.placement}
        className={classes.popper}
        modifiers={popperModifiers}
        container={props.containerEl}
      >
        <div className={classes.root} onKeyDown={onKeyDown} tabIndex={-1}>
          {props.showFilter && (
            <div className={classes.filter}>
              <InputBase
                value={filterValue}
                onChange={onFilterChange}
                fullWidth
                autoFocus
                placeholder={t('menus.multiSelect.filterResults')}
                startAdornment={<ValmetIcon icon="search" color={grey10} />}
                onKeyDown={onFilterKeyDown}
              />
            </div>
          )}
          <div className={classes.list}>
            <List
              height={Math.min(8, sortedOptions.length) * 32}
              itemCount={sortedOptions.length}
              itemSize={() => 32}
              estimatedItemSize={32}
              width="100%"
              ref={virtualListContainerRef}
              outerElementType={ListOuterElement}
            >
              {({index, style}) => (
                <Option
                  /* eslint-disable security/detect-object-injection */
                  key={sortedOptions[index].value}
                  option={sortedOptions[index]}
                  isSelected={includes(
                    sortedOptions[index],
                    props.selectedOptions,
                  )}
                  /* eslint-enable security/detect-object-injection */
                  isKeyboardFocused={keyboardFocusedOption === index}
                  onChange={selected => onOptionSelectedChange(index, selected)}
                  style={style}
                />
              )}
            </List>
          </div>
          <div className={classes.buttons}>
            {(props.showSelectAllButton === undefined ||
              props.showSelectAllButton === true) && (
              <TextButton
                onClick={onSelectAllClick}
                isDisabled={false}
                text={t('menus.multiSelect.selectAll')}
                disableMinimumWidth
              />
            )}
            <TextButton
              onClick={onClearClick}
              isDisabled={props.selectedOptions.length === 0}
              text={t('menus.multiSelect.clear')}
              disableMinimumWidth
            />
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
};

export default MultiSelectMenu;

const ListOuterElement = React.forwardRef<unknown, any>((props, ref) => {
  // The purpose of this element is to just remove tab focus from the list
  return <div {...props} ref={ref} tabIndex={-1} />;
});

const useColumnOptionStyles = makeStyles<Theme, {isKeyboardFocused: boolean}>(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      height: '32px',
      borderBottom: `1px solid ${grey15}`,
      cursor: 'pointer',
      backgroundColor: props => (props.isKeyboardFocused ? '#E6F1F6' : white),
      '&:hover': {
        backgroundColor: '#E6F1F6',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  }),
);

const Option = (props: {
  option: Option;
  isSelected: boolean;
  isKeyboardFocused: boolean;
  style: React.CSSProperties;
  onChange: (selected: boolean) => void;
}) => {
  const classes = useColumnOptionStyles({
    isKeyboardFocused: props.isKeyboardFocused,
  });
  const onContainerClick = () => {
    props.onChange(!props.isSelected);
  };

  return (
    <div
      className={classes.root}
      onClick={onContainerClick}
      style={props.style}
    >
      <ValmetCheckbox
        isChecked={props.isSelected}
        label={props.option.text}
        name={props.option.value}
        disableInputWrapper
        tabIndex={-1}
        onChange={props.onChange}
      />
    </div>
  );
};
