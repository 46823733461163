import React from 'react';
import {makeStyles, Theme, Typography} from '@material-ui/core';
import colors from '../../configs/colors';
const {grey3} = colors;
interface Props {
  text: string;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  text: {
    color: grey3,
    fontSize: '15px',
    lineHeight: '17px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
  },
}));

const NoResultsRow = (props: Props) => {
  const classes = useStyles(props);
  return (
    <div>
      <Typography className={classes.text}>{props.text}</Typography>
    </div>
  );
};

export default NoResultsRow;
