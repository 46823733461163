import * as React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import InputWrapper from './InputWrapper';
import {getIn, useFormikContext} from 'formik';
import colors from '../../configs/colors';
const {grey11} = colors;
interface Option {
  text: string;
  value: string;
}

export interface Props {
  labelTranslationKey: string;
  name: string;
  value: string;
  options: readonly Option[];
  /**
   * If true, removes the margins on the wrapper element.
   */
  disableInputWrapperMargin?: boolean;
  isDisabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const useStyles = makeStyles<Theme, {}>(() => ({
  label: {
    color: grey11,
  },
}));

const ValmetRadioButtons = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles({});
  return (
    <InputWrapper
      hasErrorText={false}
      hasHelpText={false}
      disableMargin={props.disableInputWrapperMargin}
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">{t(props.labelTranslationKey)}</FormLabel>
        <RadioGroup
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        >
          {props.options.map(o => (
            <FormControlLabel
              key={o.value}
              value={o.value}
              control={<Radio />}
              label={o.text}
              className={classes.label}
              disabled={props.isDisabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </InputWrapper>
  );
};

export default ValmetRadioButtons;

export const FormikValmetRadioButtons = (
  props: {formProperty: string} & Omit<Props, 'name' | 'value' | 'onChange'>,
) => {
  const {formProperty, ...rest} = props;
  const {values, setFieldValue, setFieldTouched} = useFormikContext<any>();
  const fieldValue = getIn(values, formProperty) as string;

  const onChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setFieldValue(formProperty, value);
    setFieldTouched(formProperty);
  };

  return (
    <ValmetRadioButtons
      {...rest}
      name={formProperty}
      onChange={onChange}
      value={fieldValue}
    />
  );
};
