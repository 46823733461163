import React, {useMemo, useState, useRef} from 'react';
import {makeStyles, Theme, Typography} from '@material-ui/core';
import colors from '../../configs/colors';
import CellBase from './CellBase';
import {useTranslation} from 'react-i18next';
import {take} from 'ramda';
import FullInfoPopover from './FullInfoPopover';
import classNames from 'classnames';
const {grey3, blue2} = colors;
interface Props {
  isOddRow: boolean;
  lines: readonly string[];
  isHighlighted?: boolean;
  setRowNotHovered: () => void;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1),
  },
  text: {
    color: grey3,
    fontSize: '13px',
    lineHeight: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: props => (props.isHighlighted ? 'bold' : 'normal'),
    textDecorationLine: props => (props.isHighlighted ? 'underline' : 'none'),
  },
  showMoreLink: {
    textDecorationLine: 'underline !important',
    fontWeight: 'normal',
    color: blue2,
    cursor: 'pointer',
  },
}));

const MultiLineTextCell = (props: Props) => {
  const {lines, isHighlighted, ...rest} = props;
  const classes = useStyles(props);
  const {t} = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null!);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLDivElement | null>(null);

  const visibleLines = useMemo(() => {
    const shownLineCount = 5;
    if (lines.length <= shownLineCount) {
      return lines;
    }

    // We return one less than the shown line count
    // because we need to show the "Show more" link
    return take(shownLineCount - 1, lines);
  }, [lines]);

  const onShowMoreClick = () => {
    setPopoverAnchorEl(containerRef.current);
    // The popover messes with mouseleave detection
    // So the row would just remain looking like it is hovered
    props.setRowNotHovered();
  };
  const onShowMoreClose = () => {
    setPopoverAnchorEl(null);
  };
  return (
    <CellBase {...rest}>
      <div className={classes.container} ref={containerRef}>
        {visibleLines.map((l, i) => (
          <React.Fragment key={i}>
            <Typography className={classes.text}>{l}</Typography>
          </React.Fragment>
        ))}

        {visibleLines.length < lines.length && (
          <>
            <Typography
              className={classNames(classes.showMoreLink, classes.text)}
              onClick={onShowMoreClick}
            >
              {t('table.multiLineText.showMore')}
            </Typography>
            <FullInfoPopover
              lines={lines}
              anchorEl={popoverAnchorEl}
              onCloseClick={onShowMoreClose}
            />
          </>
        )}
      </div>
    </CellBase>
  );
};

export default MultiLineTextCell;
