import React from 'react';
import {Link} from 'react-router-dom';

/**
 * Wraps the table cell content with a link
 * that takes the available space.
 * If the link URL is not specified or disabled
 * is set to true, the cell content is returned as is.
 */
export default function TableCellLinkWrapper({
  disabled,
  link,
  linkTarget,
  children,
}: {
  disabled: boolean;
  link: string | null | undefined;
  linkTarget: React.HTMLAttributeAnchorTarget | undefined;
  children: React.ReactNode;
}) {
  if (disabled || !link) {
    return <>{children}</>;
  }

  return (
    <Link
      to={link}
      target={linkTarget}
      rel={linkTarget === '_blank' ? 'noreferrer' : undefined}
      style={{
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </Link>
  );
}
