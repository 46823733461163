import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import ContextPanelContentHeader from './ContextPanelContentHeader';

interface Props {
  headerTranslationKey: string;
}
const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    marginTop: '28px',
    '&:first-child': {
      marginTop: '0',
    },
    '&:last-child': {
      marginBottom: '28px',
    },
  },
  content: {
    padding: theme.spacing(2),
    paddingBottom: '0',
  },
}));

const ContextPanelContentSection: React.FC<Props> = props => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <ContextPanelContentHeader translationKey={props.headerTranslationKey} />
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

export default ContextPanelContentSection;
