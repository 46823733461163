import MultiLineTextCell from './MultiLineTextCell';
import React, {useMemo} from 'react';
//let's avoid using momentjs,let's swith to date-fns
//for now to reduce lib bundle we remove Moment lib
export interface TimestampInfo {
  timestamp: {format: (format: string) => any};
  userName: string;
}

interface Props {
  isOddRow: boolean;
  data: Readonly<TimestampInfo>;
  isHighlighted?: boolean;
  setRowNotHovered: () => void;
}

const DateTimeFormat = 'DD/MM/YYYY HH:mm';

const TimestampInfoCell = (props: Props) => {
  const {data, ...rest} = props;
  const lines = useMemo(
    () => [data.timestamp.format(DateTimeFormat), data.userName],
    [data],
  );
  return <MultiLineTextCell {...rest} lines={lines} />;
};

export default TimestampInfoCell;
