import {Theme} from '@material-ui/core';

export default (_: Theme) => {
  return {
    MuiTableCell: {
      head: {
        padding: '14px 13px 15px 13px',
      },
    },
  };
};
