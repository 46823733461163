import {Theme} from '@material-ui/core';
import colors from './colors';
const {black, grey0, green1, white, red1, yellow1, blue2} = colors;
export const generalFontStack = [
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const primaryTextColor = black;
export const spacingUnit = 8;
export const gutter = spacingUnit * 2.5;

export const templateTheme: Theme = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  palette: {
    primary: {
      dark: grey0,
      light: grey0,
      main: grey0,
      contrastText: white,
    },
    secondary: {
      dark: green1,
      light: green1,
      main: green1,
      contrastText: white,
    },
    info: {
      dark: blue2,
      light: blue2,
      main: blue2,
      contrastText: white,
    },
    success: {
      dark: green1,
      light: green1,
      main: green1,
      contrastText: white,
    },
    warning: {
      dark: yellow1,
      light: yellow1,
      main: yellow1,
      contrastText: white,
    },
    error: {
      dark: red1,
      light: red1,
      main: red1,
      contrastText: white,
    },
  },
  shape: {
    borderRadius: 2,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  typography: {
    fontFamily: generalFontStack,
    fontSize: 13,
    h1: {
      fontFamily: generalFontStack,
      fontSize: '31px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      color: primaryTextColor,
      lineHeight: '48px',
    },
    h2: {
      fontFamily: generalFontStack,
      fontSize: '23px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      color: primaryTextColor,
      lineHeight: '36px',
    },
    h3: {
      fontFamily: generalFontStack,
      fontSize: '17px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      color: primaryTextColor,
      lineHeight: '24px',
    },
    body1: {
      fontFamily: generalFontStack,
      color: primaryTextColor,
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
    body2: {
      fontFamily: generalFontStack,
      color: primaryTextColor,
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '24px',
    },
    caption: {
      fontFamily: generalFontStack,
      color: primaryTextColor,
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '16px',
    },
    button: {
      fontFamily: generalFontStack,
      color: primaryTextColor,
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '16px',
      letterSpacing: '0.85px',
      textTransform: 'none',
    },
  },
  // Removes all shadows
  shadows: Array(25).fill('none') as any,
};
