import React from 'react';
import {Hidden, makeStyles} from '@material-ui/core';
import PageSizeSelector from './PageSizeSelector';
import PageIndicator from './PageIndicator';
import PageSelector from './PageSelector';
import PreviousButton from './PreviousButton';
import NextButton from './NextButton';

export interface Props {
  /**
   * The current page (0-based)
   */
  page: number;
  /**
   * The total number of results (not pages)
   */
  totalResults: number;
  /**
   * The current page size (must be greater than 0)
   */
  pageSize: number;
  /**
   * The available page sizes for the user to choose
   */
  pageSizes: readonly number[];
  /**
   * How many pages should always be visible (default 5)
   */
  visiblePages?: number;
  /**
   * Called when the user changes the page
   */
  onPageChange: (page: number) => void;
  /**
   * Called when the user changes the page size
   */
  onPageSizeChange: (pageSize: number) => void;
}

const DefaultVisiblePages = 5;

const useStyles = makeStyles(() => ({
  desktopRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileRoot: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function DesktopPagination(props: Props) {
  const classes = useStyles();
  const totalPages = Math.ceil(props.totalResults / props.pageSize);
  return (
    <div className={classes.desktopRoot}>
      <PageSizeSelector
        pageSize={props.pageSize}
        pageSizes={props.pageSizes}
        onChange={props.onPageSizeChange}
      />
      <PageIndicator
        align="left"
        page={props.page}
        totalResults={props.totalResults}
        pageSize={props.pageSize}
      />
      <PageSelector
        page={props.page}
        totalPages={totalPages}
        visiblePages={props.visiblePages ?? DefaultVisiblePages}
        onChange={props.onPageChange}
      />
    </div>
  );
}

function MobilePagination(props: Props) {
  const classes = useStyles();
  const totalPages = Math.ceil(props.totalResults / props.pageSize);

  const isFirstPage = props.page === 0;
  const isLastPage = props.page === totalPages - 1;

  const onPreviousClick = () => {
    if (props.page === 0) {
      return;
    }

    props.onPageChange(props.page - 1);
  };
  const onNextClick = () => {
    const maxPage = Math.floor(props.totalResults / props.pageSize);
    if (props.page === maxPage) {
      return;
    }

    props.onPageChange(props.page + 1);
  };
  return (
    <div className={classes.mobileRoot}>
      <PreviousButton isDisabled={isFirstPage} onClick={onPreviousClick} />
      <PageIndicator
        align="center"
        page={props.page}
        totalResults={props.totalResults}
        pageSize={props.pageSize}
      />
      <NextButton isDisabled={isLastPage} onClick={onNextClick} />
    </div>
  );
}

const ValmetPagination: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Hidden smDown>
        <DesktopPagination {...props} />
      </Hidden>
      <Hidden mdUp>
        <MobilePagination {...props} />
      </Hidden>
    </>
  );
};

ValmetPagination.defaultProps = {
  visiblePages: DefaultVisiblePages,
};

export default ValmetPagination;
